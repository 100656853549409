<template>
  <section class="section">
    <div class="container ist-container">
      <h1 class="title is-4">New Simulation</h1>
      <div class="stepIndicatorMargins">
        <StepIndicator
          v-model="activeStep"
          :labels="stepLabels"
          :warnings="stepWarnings"
        />
      </div>
      <h2 class="title is-3">
        {{ activeStepName }}
      </h2>
      <!-- SETUP -->
      <div v-if="activeStepName === 'Setup'">
        <div class="columns">
          <div class="column is-6">
            <!-- Workflow -->
            <FieldRadio
              id="setup-workflow"
              v-model="workflow"
              label="Select workflow"
              :options="workflowOptions"
            />
            <!-- Title -->
            <Field
              id="setup-title"
              v-model="title"
              label="Title"
              :validate="highestVisitedStep > 0"
            />
            <template v-if="workflow.value === 'simulation'">
              <!-- Drum -->
              <FieldSelect
                id="setup-drum"
                v-model="drum"
                label="Select a coater"
                :options="drumOptions"
              />
              <!-- Drum Rotation Speed -->
              <Field
                id="setup-drum-rotation-speed"
                v-model="drumRotationSpeed"
                label="Coater rotation speed (rpm)"
                :validate="highestVisitedStep > 0"
                :vmin="1"
                :vmax="100"
              />
              <!-- expert mode -->
              <FieldCheckbox
                id="setup-expert"
                v-model="setupExpertMode"
                label=""
                :options="expertModeOptions"
              />
              <div v-if="setupExpertMode.value.expertMode">
                <Field
                  id="setup-time-end"
                  v-model="tEnd"
                  label="Simulation duration (s)"
                  :validate="highestVisitedStep > 0"
                  :vmin="15"
                  :vmax="100"
                />
                <Field
                  id="setup-time-step"
                  v-model="tStep"
                  label="Time step (µs)"
                  :validate="highestVisitedStep > 0"
                  :vmin="1"
                  :vmax="100"
                />
              </div>
            </template>
          </div>
          <div v-if="workflow.value === 'simulation'" class="column is-6 has-text-centered">
            <figure class="image is-inline-block" style="width: 50%">
              <img :src="coaterImage" />
            </figure>
            <p class="mt">Contour of the selected coater.</p>
          </div>
        </div>
      </div>
      <!-- end of SETUP -->
      <!-- TABLETS -->
      <div v-if="activeStepName === 'Tablets'">
        <div class="columns">
          <div class="column is-6">
            <!-- Volume Fraction -->
            <Field
              id="tablets-mass"
              v-model="loadingMass"
              label="Mass of tablets (kg)"
              :validate="highestVisitedStep > 1"
              :vmin="0.1"
              :vmax="maxLoadingMass"
            />
          </div>
        </div>
        <div class="columns">
          <div class="column is-6">
            <h2 class="title is-5">Tablet Shape</h2>
            <Field
              id="tablets-L1"
              v-model="L1"
              label="Band thickness L<sub>1</sub> (mm)"
              :validate="highestVisitedStep > 1"
              vpositive
              :vmax="Number(L2.value)"
            />
            <Field
              id="tablets-L2"
              v-model="L2"
              label="Total thickness L<sub>2</sub> (mm)"
              :validate="highestVisitedStep > 1"
              :vmin="Number(L1.value)"
            />
            <Field
              id="tablets-L3"
              v-model="L3"
              label="Band diameter L<sub>3</sub> (mm)"
              :validate="highestVisitedStep > 1"
              :vmin="Number(minimumBandDiameter)"
            />
          </div>
          <div class="column is-6 has-text-centered">
            <figure class="image is-inline-block" style="width: 80%">
              <img src="../assets/tabletparameters.png" />
            </figure>
            <p class="mt">Tablet shape parameters.</p>
          </div>
        </div>
        <!-- end of Tablet Shape div.columns -->
        <div class="columns">
          <div class="column is-6">
            <h2 class="title is-5">Material</h2>
            <Field
              id="tablets-density"
              v-model="density"
              label="Particle density (g/L)"
              :validate="highestVisitedStep > 1"
              :vmin="1000"
              :vmax="5000"
            />
          </div>
        </div>
        <h2 class="title is-5">Interactions</h2>
        <FieldCheckbox
          id="tablet-expert"
          v-model="expertMode"
          :options="expertModeOptions"
        />
        <h3 class="title is-6">Particle-Particle Interactions</h3>
        <div class="columns">
          <div class="column is-3">
            <Field
              id="tablets-friction-pp"
              v-model="frictionPP"
              label="Sliding friction (-)"
              :validate="highestVisitedStep > 1"
              :vmin="0.1"
              :vmax="1"
            />
          </div>
          <div class="column is-3" v-if="expertMode.value.expertMode">
            <Field
              id="tablets-friction-pp"
              v-model="stiffnessPP"
              label="Stiffness (N/m)"
              :validate="highestVisitedStep > 1"
              :vmin="5000"
              :vmax="100000"
            />
          </div>
          <div class="column is-3" v-if="expertMode.value.expertMode">
            <Field
              id="tablets-friction-pp"
              v-model="restitutionPP"
              label="Restitution (-)"
              :validate="highestVisitedStep > 1"
              :vmin="0.3"
              :vmax="1"
            />
          </div>
        </div>
        <h3 class="title is-6">Particle-Wall Interactions</h3>
        <div class="columns">
          <div class="column is-3">
            <Field
              id="tablets-friction-pw"
              v-model="frictionPW"
              label="Sliding friction (-)"
              :validate="highestVisitedStep > 1"
              :vmin="0.1"
              :vmax="1"
            />
          </div>
          <div class="column is-3" v-if="expertMode.value.expertMode">
            <Field
              id="tablets-friction-pw"
              v-model="stiffnessPW"
              label="Stiffness (N/m)"
              :validate="highestVisitedStep > 1"
              :vmin="5000"
              :vmax="100000"
            />
          </div>
          <div class="column is-3" v-if="expertMode.value.expertMode">
            <Field
              id="tablets-friction-pw"
              v-model="restitutionPW"
              label="Restitution (-)"
              :validate="highestVisitedStep > 1"
              :vmin="0.3"
              :vmax="1"
            />
          </div>
        </div>
      </div>
      <!-- end of TABLETS -->
      <!-- SPRAY -->
      <div v-if="activeStepName === 'Spray'">
        <div class="columns">
          <div class="column is-2">
            <Field
              id="spray-start"
              v-model="nozzle.start"
              label="Start (s)"
              :validate="highestVisitedStep > 2"
            />
          </div>
          <div class="column is-2">
            <Field
              id="spray-end"
              v-model="nozzle.end"
              label="End (s)"
              :validate="highestVisitedStep > 2"
            />
          </div>
        </div>
        <h2 class="title is-5">Spray Materials</h2>
        <h2 class="title is-6">Solvents</h2>
        <template v-for="(solvent, index) in solvents" :key="index">
          <div class="columns">
            <div class="column is-2">
              <!-- Volume Fraction -->
              <Field
                :id="`spray-volume-fraction-${index}`"
                v-model="solvent.volumeFraction"
                label="Volume fraction (-)"
                :validate="highestVisitedStep > 2"
                :vmin="0"
                :vmax="1"
              />
            </div>
            <div class="column is-2">
              <!-- Solvent -->
              <FieldSelect
                :id="`spray-solvent-${index}`"
                v-model="solvent.material" 
                label="Solvent"
                :options="solventOptions"
              />
            </div>
            <div class="column is-2">
              <!-- Density -->
              <Field
                :id="`spray-volume-density-${index}`"
                v-model="solvent.density"
                label="Density (g/L)"
                :validate="highestVisitedStep > 2"
                vpositive
              />
            </div>
            <div class="column is-1">
              <div class="field">
                <label class="label">&nbsp;</label>
                <button
                  v-if="index != 0"
                  class="button is-danger is-outlined"
                  @click="removeBlock(solvents, index)"
                >
                  Remove
                </button>
              </div>
            </div>
          </div>
        </template>
        <button
          style="margin-top: -0.5em; margin-bottom: 2em"
          class="button"
          :disabled="solvents.length >= 5"
          @click="addBlock(solvents, solvent_template)"
        >
          Add solvent
        </button>

        <h2 class="title is-6">Solids</h2>
        <template v-for="(material, index) in materials" :key="index">
          <div class="columns">
            <div class="column is-2">
              <!-- Volume Fraction -->
              <Field
                :id="`spray-volume-fraction-${index}`"
                v-model="material.volumeFraction"
                label="Volume fraction (-)"
                :validate="highestVisitedStep > 2"
                :vmin="0"
                :vmax="1"
              />
            </div>
            <div class="column is-2">
              <!-- Material -->
              <Field
                :id="`spray-material-${index}`"
                v-model="material.material"
                label="Solid"
                :validate="highestVisitedStep > 2"
              />
            </div>
            <div class="column is-2">
              <!-- Density -->
              <Field
                :id="`spray-volume-density-${index}`"
                v-model="material.density"
                label="Density (g/L)"
                :validate="highestVisitedStep > 2"
                vpositive
              />
            </div>
            <div class="column is-1">
              <div class="field">
                <label class="label">&nbsp;</label>
                <button
                  class="button is-danger is-outlined"
                  @click="removeBlock(materials, index)"
                >
                  Remove
                </button>
              </div>
            </div>
          </div>
        </template>
        <button
          style="margin-top: -0.5em; margin-bottom: 2em"
          class="button"
          :disabled="materials.length >= 5"
          @click="addBlock(materials, material_template)"
        >
          Add material
        </button>

        <h2 class="title is-5">Nozzle Setup</h2>
        <div class="columns">
          <div class="column is-6">
            <h2 class="title is-6">Nozzle Direction</h2>
            <div class="columns">
              <div class="column">
                <Field
                  id="spray-dirX"
                  v-model="nozzle.dirX"
                  label="X (m)"
                  :validate="highestVisitedStep > 2"
                />
              </div>
              <div class="column">
                <Field
                  id="spray-dirY"
                  v-model="nozzle.dirY"
                  label="Y (m)"
                  :validate="highestVisitedStep > 2"
                />
              </div>
              <div class="column">
                <Field
                  id="spray-dirZ"
                  v-model="nozzle.dirZ"
                  label="Z (m)"
                  :validate="highestVisitedStep > 2"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <h2 class="title is-6">Major Axis</h2>
            <div class="columns">
              <div class="column">
                <Field
                  id="spray-axisX"
                  v-model="nozzle.axisX"
                  label="X (m)"
                  :validate="highestVisitedStep > 2"
                />
              </div>
              <div class="column">
                <Field
                  id="spray-axis"
                  v-model="nozzle.axisY"
                  label="Y (m)"
                  :validate="highestVisitedStep > 2"
                />
              </div>
              <div class="column">
                <Field
                  id="spray-axisZ"
                  v-model="nozzle.axisZ"
                  label="Z (m)"
                  :validate="highestVisitedStep > 2"
                />
              </div>
            </div>
          </div>
          <div class="column">
            <h2 class="title is-6">Opening Angles</h2>
            <div class="columns">
              <div class="column is-4">
                <Field
                  :id="`spray-major-${index}`"
                  v-model="nozzle.majorAngle"
                  label="Major angle (°)"
                  :validate="highestVisitedStep > 2"
                />
              </div>
              <div class="column is-4">
                <Field
                  :id="`spray-minor-${index}`"
                  v-model="nozzle.minorAngle"
                  label="Minor angle (°)"
                  :validate="highestVisitedStep > 2"
                />
              </div>
            </div>
          </div>
        </div>
        <!-- nozzles -->
        <h2 class="title is-5">Nozzle Positions &amp; Spray Rate</h2>
        <template v-for="(nozzle, index) in positions" :key="index">
          <div class="columns">
            <div class="column is-6">
              <h2 class="title is-6">Nozzle {{ index + 1 }}</h2>
              <div class="columns">
                <div class="column">
                  <Field
                    :id="`spray-posX-${index}`"
                    v-model="nozzle.posX"
                    label="X (m)"
                    :validate="highestVisitedStep > 2"
                  />
                </div>
                <div class="column">
                  <Field
                    :id="`spray-posY-${index}`"
                    v-model="nozzle.posY"
                    label="Y (m)"
                    :validate="highestVisitedStep > 2"
                  />
                </div>
                <div class="column">
                  <Field
                    :id="`spray-posZ-${index}`"
                    v-model="nozzle.posZ"
                    label="Z (m)"
                    :validate="highestVisitedStep > 2"
                  />
                </div>
              </div>
            </div>
            <div class="column is-2">
              <h2 class="title is-6 has-text-white">Spray Rate</h2>
              <Field
                :id="`spray-rate-${index}`"
                v-model="nozzle.rate"
                label="Spray Rate (g/min)"
                :validate="highestVisitedStep > 2"
              />
            </div>
            <div v-if="index > 0" class="column is-2">
              <button
                class="button is-danger is-outlined"
                style="margin-top: 4.5em"
                @click="removeBlock(positions, index)"
              >
                Remove
              </button>
            </div>
          </div>
        </template>
        <button
          style="margin-top: -0.5em; margin-bottom: 2em"
          class="button"
          @click="increment($event)"
        >
          Add nozzle
        </button>

        <h2 class="title is-5">Droplet Radius</h2>

        <div class="columns">
          <div class="column is-8">
            <div class="columns">
              <div class="column is-3">
                <Field
                  id="spray-mean"
                  v-model="nozzle.radiusMean"
                  label="Mean radius (µm)"
                  :validate="highestVisitedStep > 2"
                />
              </div>
              <div class="column" v-if="nozzle.expertMode.value.expertMode">
                <Field
                  id="spray-min"
                  v-model="nozzle.radiusMin"
                  label="Minimum (µm)"
                  :validate="highestVisitedStep > 2"
                />
              </div>
              <div class="column" v-if="nozzle.expertMode.value.expertMode">
                <Field
                  id="spray-max"
                  v-model="nozzle.radiusMax"
                  label="Maximum (µm)"
                  :validate="highestVisitedStep > 2"
                />
              </div>
              <div class="column" v-if="nozzle.expertMode.value.expertMode">
                <Field
                  id="spray-sigma"
                  v-model="nozzle.radiusSigma"
                  label="Std. deviation (µm)"
                  :validate="highestVisitedStep > 2"
                />
              </div>
            </div>
          </div>
        </div>
        <FieldCheckbox
          id="spray-expert"
          v-model="nozzle.expertMode"
          label=""
          :options="expertModeOptions"
        />
      </div>
      <!-- end of SPRAY -->
      <!-- COMPARISON -->
      <div v-if="activeStepName === 'Compare'">
        <p v-if="isJobsLoading">
          <Loader :is-loading="isJobsLoading" />
          Loading previous simulations...
        </p>
        <div v-else id="s1-sim-comparison">
          <template v-if="jobsOptions.length > 0">
            <TableCheckbox
              id="compare-select"
              v-model="jobsSelection"
              v-if="jobsOptions.length > 0"
              :options="jobsOptions"
              class="table-checkbox"
            />
            <h3 class="title is-5 title-breathe">Coefficient of Inter-Tablet Coating Variability</h3>
            <div class="plot-container">
              <figure v-for="job in currentlySelectedJobs" :key="job" class="plot">
                <p v-if="job" class="plot-sim-title">{{jobsAll[job].name}}</p>
                <img
                  v-if="job in plotsLoaded"
                  :src="plotsLoaded[job].CoV"
                >
              </figure>
            </div>
            <h3 class="title is-5 title-breathe">Tablets Sprayed</h3>
            <div class="plot-container">
              <figure v-for="job in currentlySelectedJobs" :key="job" class="plot">
                <p v-if="job" class="plot-sim-title">{{jobsAll[job].name}}</p>
                <img
                  v-if="job in plotsLoaded"
                  :src="plotsLoaded[job].spray"
                >
              </figure>
            </div>
            <h3 class="title is-5 title-breathe">Velocity Distribution in Coater</h3>
            <div class="plot-container">
              <figure v-for="job in currentlySelectedJobs" :key="job" class="plot">
                <p v-if="job" class="plot-sim-title">{{jobsAll[job].name}}</p>
                <img
                  v-if="job in plotsLoaded"
                  :src="plotsLoaded[job].velocity"
                >
              </figure>
            </div>
            <h3 class="title is-5 title-breathe">Velocity Distribution in Spray Zone</h3>
            <div class="plot-container">
              <figure v-for="job in currentlySelectedJobs" :key="job" class="plot">
                <p v-if="job" class="plot-sim-title">{{jobsAll[job].name}}</p>
                <img
                  v-if="job in plotsLoaded"
                  :src="plotsLoaded[job].velocity_sprayzone"
                >
              </figure>
            </div>
          </template>
          <p v-else style="margin-bottom: 2em;">Please run simulations to compare.</p>
        </div>
      </div>
      <div v-if="workflow.value === 'comparison' && activeStepName === 'Run'">
        <p>Comparison plots containing the averages for the selected simulations can be generated.</p>
        <p style="margin-top: 1em; margin-bottom: 0.5em;">Selected simulations:</p>
        <ul>
          <li v-for="job in currentlySelectedJobs" :key="job">
            {{jobsAll[job].name}}
          </li>
        </ul>
        <p v-if="!allowedToSubmit" style="margin-top: 1em;">Please select between 2 and 5 simulations to compare.</p>
        <p style="margin-top: 2em;">This run will consume {{tokenCounter}} tokens ({{tokensAvailable}} tokens available).</p>
        <button
          id="submit"
          class="button mt"
          :class="{
            'is-warning': !allowedToSubmit,
            'is-success': allowedToSubmit,
          }"
          :disabled="isSubmitting || !allowedToSubmit"
          @click="submitJob"
        >
          <Loader :is-loading="isSubmitting" />
          Run comparison
        </button>
      </div>
      <!-- end of COMPARISON -->
      <!-- RUN -->
      <div v-if="workflow.value === 'simulation' && activeStepName === 'Run'">
        <h2 class="title is-5">Inputs Summary</h2>
        <h3 class="title is-5" style="margin-top: 1.5em">Setup</h3>
        <SummaryItem label="Title" :item="title" />
        <SummaryItem label="Coater" :value="drumOptions[drum.value].label" />
        <SummaryItem
          label="Coater rotation speed (rpm)"
          :item="drumRotationSpeed"
        />
        <div v-if="setupExpertMode.value.expertMode">
          <SummaryItem label="Simulation duration (s)" :item="tEnd" />
          <SummaryItem label="Time step (µs)" :item="tStep" />
        </div>
        <h3 class="title is-5" style="margin-top: 1.5em">Tablets</h3>
        <SummaryItem label="Load (kg)" :item="loadingMass" />
        <div class="table-container">
          <table
            id="InteractionsTable"
            class="table ist-table is-striped"
            style="margin-top: 1.5em"
          >
            <thead>
              <tr>
                <th>Band thickness L1 (mm)</th>
                <th>Total thickness L2 (mm)</th>
                <th>Band diameter L3 (mm)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ L1.value }}</td>
                <td>{{ L2.value }}</td>
                <td>{{ L3.value }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <SummaryItem :label="'Particle density (g/L)'" :item="density" />
        <div class="table-container">
          <table
            id="InteractionsTables"
            class="table ist-table is-striped"
            style="margin-top: 1.5em"
          >
            <thead>
              <tr>
                <th>Interaction Type</th>
                <th>Sliding friction (-)</th>
                <th v-if="expertMode.value.expertMode">Stiffness (N/m)</th>
                <th v-if="expertMode.value.expertMode">Restitution (-)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Particle-Particle</td>
                <td>{{ frictionPP.value }}</td>
                <td v-if="expertMode.value.expertMode">
                  {{ stiffnessPP.value }}
                </td>
                <td v-if="expertMode.value.expertMode">
                  {{ restitutionPP.value }}
                </td>
              </tr>
              <tr>
                <td>Particle-Wall</td>
                <td>{{ frictionPW.value }}</td>
                <td v-if="expertMode.value.expertMode">
                  {{ stiffnessPW.value }}
                </td>
                <td v-if="expertMode.value.expertMode">
                  {{ restitutionPW.value }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <h3 class="title is-5" style="margin-top: 1.5em">Spray</h3>
        <SummaryItem :label="'Start (s)'" :item="nozzle.start" />
        <SummaryItem :label="'End (s)'" :item="nozzle.end" />
        <p v-if="!volumeValidates" class="has-text-warning">Please ensure volume fractions add up to exactly 1.</p>
        <div class="table-container">
          <table
            id="SolventsTable"
            class="table ist-table is-striped"
            style="margin-top: 1.5em"
          >
            <thead :class="{'has-background-warning': !volumeValidates}">
              <tr>
                <th>Volume fraction</th>
                <th>Material</th>
                <th>Density (g/L)</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="solvent in solvents" :key="solvent">
                <td>{{ solvent.volumeFraction.value }}</td>
                <td>{{ solvent.material.value }}</td>
                <td>{{ solvent.density.value }}</td>
              </tr>
              <tr v-for="material in materials" :key="material">
                <td>{{ material.volumeFraction.value }}</td>
                <td>{{ material.material.value }}</td>
                <td>{{ material.density.value }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="table-container">
          <table
            id="DirectionTable"
            class="table ist-table is-striped"
            style="margin-top: 1.5em"
          >
            <thead>
              <tr>
                <th></th>
                <th>X</th>
                <th>Y</th>
                <th>Z</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Nozzle Direction</td>
                <td>{{ nozzle.dirX.value }}</td>
                <td>{{ nozzle.dirY.value }}</td>
                <td>{{ nozzle.dirZ.value }}</td>
              </tr>
              <tr>
                <td>Major Axis</td>
                <td>{{ nozzle.axisX.value }}</td>
                <td>{{ nozzle.axisY.value }}</td>
                <td>{{ nozzle.axisZ.value }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <SummaryItem
          :label="'Major opening angle (°)'"
          :item="nozzle.majorAngle"
        />
        <SummaryItem
          :label="'Minor opening angle (°)'"
          :item="nozzle.minorAngle"
        />
        <div class="table-container">
          <table
            id="NozzlePositions"
            class="table ist-table is-striped"
            style="margin-top: 1.5em"
          >
            <thead>
              <tr>
                <th></th>
                <th>X (m)</th>
                <th>Y (m)</th>
                <th>Z (m)</th>
                <th>Spray Rate (g/min)</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(nozzle, index) in positions" :key="nozzle">
                <td>Nozzle {{ index + 1 }}</td>
                <td>{{ nozzle.posX.value }}</td>
                <td>{{ nozzle.posY.value }}</td>
                <td>{{ nozzle.posZ.value }}</td>
                <td>{{ nozzle.rate.value }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <template v-if="nozzle.expertMode.value.expertMode">
          <SummaryItem
            label="Mean droplet radius (µm)"
            :value="
              nozzle.radiusMean.value + ' &plusmn; ' + nozzle.radiusSigma.value
            "
          />
          <SummaryItem
            label="Droplet radius range (µm)"
            :value="nozzle.radiusMin.value + ' - ' + nozzle.radiusMax.value"
          />
        </template>
        <template v-else>
          <SummaryItem
            :label="'Mean droplet radius (µm)'"
            :value="nozzle.radiusMean.value"
          />
        </template>
        <h3 class="title is-5" style="margin-top: 1.5em">Visualisation of Drum and Nozzles</h3>
        <Drum :fileObj="drumOptions[drum.value].url" :dir="nozzle" :pos="positions" :rpm="drumRotationSpeed" :world="world" />
        <p style="margin-top: 2em;">
          This simulation will consume {{tokenCounter}} tokens ({{tokensAvailable}} tokens available).
          <span v-if="canEstimateRuntime">
            It will take approximately {{runtimeEstimate}} hours to complete.
          </span>
        </p>
        <button
          id="submit"
          class="button mt"
          :class="{ 
            'is-warning': !allowedToSubmit,
            'is-success': allowedToSubmit,
          }"
          :disabled="isSubmitting || !allowedToSubmit"
          @click="submitJob"
        >
          <Loader :is-loading="isSubmitting" />
          Run simulation
        </button>
      </div>
      <!-- end of RUN -->
      <StepButtons v-model="activeStep" :numSteps="stepLabels.length" />
    </div>
    <!-- end of container -->
  </section>
</template>

<script>
import gql from 'graphql-tag';
import Field from "ist-skeleton-vue/src/components/Simulation/Field.vue";
import FieldCheckbox from "ist-skeleton-vue/src/components/Simulation/FieldCheckbox.vue";
import FieldRadio from "ist-skeleton-vue/src/components/Simulation/FieldRadio.vue";
import FieldSelect from "ist-skeleton-vue/src/components/Simulation/FieldSelect.vue";
import Loader from 'ist-skeleton-vue/src/components/Loader.vue';
import StepIndicator from "ist-skeleton-vue/src/components/Simulation/StepIndicator.vue";
import SummaryItem from "ist-skeleton-vue/src/components/Simulation/SummaryItem.vue";
import StepButtons from "../components/StepButtons.vue";
import Drum from "../components/VtkScenes/Drum.vue";
import coaterData from "../assets/coaters.json";
import TableCheckbox from "../components/TableCheckbox.vue";

export default {
  components: {
    Drum,
    Field,
    FieldCheckbox,
    FieldRadio,
    FieldSelect,
    Loader,
    StepButtons,
    StepIndicator,
    SummaryItem,
    TableCheckbox,
  },
  data() {
    return {
      // workflow
      activeStep: 0,
      highestVisitedStep: 0,
      /// tokens
      tokensAvailable: 0,
      tokenCounter: 9,
      // form status
      isSubmitting: false,
      jobValidates: false,
      stepWarnings: [false, false, false, false],
      // SETUP
      workflowOptions: [
        { value: "simulation", label: "New simulation" },
        { value: "comparison", label: "Compare previous simulations"}
      ],
      index: 0,
      drumOptions: [
        {
          value: 0,
          url: `${process.env.BASE_URL}rotated_drum.stl`,
          label: "Rotated drum",
        },
        {
          value: 1,
          url: `${process.env.BASE_URL}pilot_scale.stl`,
          label: "Pilot scale",
        },
        {
          value: 2,
          url: `${process.env.BASE_URL}industrial_scale.stl`,
          label: "Industrial scale",
        },
      ],
      // TABLETS
      expertModeOptions: [
        { value: "expertMode", label: "Enable advanced settings" },
      ],
      // SPRAY
      solventOptions: [
        { value: "water", label: "water", density: "1000" },
        { value: "ethanol", label: "ethanol", density: "789" },
        { value: "acetone", label: "acetone", density: "784" },
        {
          value: "methylene_chloride",
          label: "methylene chloride",
          density: "1330",
        },
        { value: "custom", label: "-custom-", density: "" },
      ],
      //
      // USER DATA BELOW
      //
      // setup
      workflow: { value: "simulation" },
      title: { value: "", ok: false },
      drum: { value: 0, ok: true },
      drumRotationSpeed: { value: "", ok: false },
      setupExpertMode: { value: {expertMode: false } },
      tEnd: { value: 60, ok: true },
      tStep: { value: 10, ok: true },
      // tablets
      loadingMass: { value: "", ok: false },
      L1: { value: "2.6", ok: true },
      L2: { value: "4.6", ok: true },
      L3: { value: "12", ok: true },
      density: { value: "", ok: false },
      expertMode: { value: { expertMode: false } },
      frictionPP: { value: "0.5", ok: true },
      frictionPW: { value: "0.5", ok: true },
      stiffnessPP: { value: "10000", ok: true },
      stiffnessPW: { value: "20000", ok: true },
      restitutionPP: { value: "0.699999988", ok: true },
      restitutionPW: { value: "0.699999988", ok: true },
      // spray
      //sprayDensity: { value: "1000", ok: true },  // TODO: ask RCPE
      solvents: [
        {
          volumeFraction: { value: "1", ok: true },
          material: { value: "water", ok: true },
          density: { value: "1000", ok: true },
        },
      ],
      solvent_template: {
        volumeFraction: { value: "", ok: false },
        material: { value: "water", ok: true },
        density: { value: "1000", ok: true },
      },
      materials: [
      ],
      material_template: {
        volumeFraction: { value: "", ok: false },
        material: { value: "", ok: false },
        density: { value: "", ok: false },
      },
      volumeValidates: false,
      //nozzles positions
      positions: [
        {
          posX: { value: "0", ok: true },
          posY: { value: "0", ok: true },
          posZ: { value: "0", ok: true },
          rate: { value: "4.8", ok: true },
        },
      ],
      nozzle: {
        dirX: { value: "0", ok: true },
        dirY: { value: "-1", ok: true },
        dirZ: { value: "-1", ok: true },
        axisX: { value: "1", ok: true },
        axisY: { value: "0", ok: true },
        axisZ: { value: "0", ok: true },
        radiusMean: { value: "10", ok: true },
        radiusSigma: { value: "5", ok: true },
        radiusMin: { value: "7", ok: true },
        radiusMax: { value: "13", ok: true },
        majorAngle: { value: "30", ok: true },
        minorAngle: { value: "30", ok: true },
        start: { value: "0.5", ok: true },
        end: { value: "10000", ok: true },
        volume: { value: "1", ok: true },
        expertMode: { value: { expertMode: false } },
      },
      // compare
      isJobsLoading: false, // loading list of all jobs in the beginning
      isPlotsLoading: 0, // number because we might be loading more than one job
      jobsAll: {},  // all successful user jobs
      jobsOptions: [], // for building the HTML checkboxes
      jobsSelection: { value: {}, }, // currently selected jobs from the options
      plotsLoaded: {}, // cache plots once selected
    };
  },
  computed: {
    canEstimateRuntime() {
      return this.L1.ok && this.L2.ok && this.L3.ok && this.loadingMass.ok && this.density.ok && this.tEnd.ok && this.tStep.ok;
    },
    runtimeEstimate() {
      const L1 = this.L1.value / 1000; // from mm to m
      const L2 = this.L2.value / 1000;
      const L3 = this.L3.value / 1000;
      const beta = 2.0 * Math.atan((L2 - L1) / L3);
      const R2 = L3 / (2.0 * Math.sin(beta));
      const t2 = (L2 - L1) / 2.0;
      // Volume calculation
      const volumeBand = Math.PI / 12.0 * (3.0 * L3 * L3 * L1 + 2.0 * L1 * L1 * L1);
      const volumeCap = Math.PI / 3.0 * t2 * t2 * (3.0 * R2 - t2);
      const tabletVolume = volumeBand + 2.0 * volumeCap;
      // Number of tablets calculation
      const allTabletsVolume = this.loadingMass.value / this.density.value;
      const numberTablets = Math.ceil(allTabletsVolume / tabletVolume);
      // runtime calculation
      const simulationTime = this.tEnd.value;
      const timeStep = this.tStep.value / 1e6; // from microseconds to s
      const estimatedSimulationSpeed = -11.56 * Math.log(numberTablets) + 207.98; // [steps/s]
      const speedFactor = 2.0; // how many times faster than RTX 3090 which was used for benchmarking?
      const estimatedSimulationDuration = simulationTime / (speedFactor * estimatedSimulationSpeed * timeStep); // [s]
      const estimate = Math.round(estimatedSimulationDuration / 3600); // [h]
      //console.log(`tabletVolume ${tabletVolume} m³ | numberTablets ${numberTablets} | sim. speed ${estimatedSimulationSpeed} steps/s`);
      //console.log(`tEnd ${simulationTime} s | tStep ${timeStep} | duration ${estimatedSimulationDuration} s |`);
      return estimate;
    },
    world() {
      return coaterData[this.drum.value].world;
    },
    maxLoadingMass() {
      return [5, 50, 500][this.drum.value];
    },
    stepLabels() {
      if (this.workflow.value === "simulation")
        return ["Setup", "Tablets", "Spray", "Run"];
      return ["Setup", "Compare", "Run"]; // workflow "comparison"
    },
    allowedToSubmit() {
      return this.tokenCounter <= this.tokensAvailable && this.jobValidates;
    },
    activeStepName() {
      return this.stepLabels[this.activeStep];
    },
    minimumBandDiameter() {
      return Math.sqrt(this.L2.value ** 2 - this.L1.value ** 2).toPrecision(5);
    },
    coaterImage() {
      // image filename should be the same as the corresponding .stl file
      return require(`../assets/coater_${this.drum.value}.png`); // eslint-disable-line
    },
    currentlySelectedJobs() {
      return Object.entries(this.jobsSelection.value).filter(([, selected]) => selected).map(([id, ]) => id);
    },
  },
  async mounted() {
    this.fetchTokens();
  },
  methods: {
    async fetchTokens() {
      try {
        const response = await this.$apollo.query({
          query: gql`
          query getUserSubscription($product_name: ProductName!) {
            account: getUserSubscription(product_name: $product_name) {
              tokens_available
            }
          }`,
          variables: {
            product_name: this.$product.id,
          },
        });
        this.tokensAvailable = response.data.account.tokens_available;
      } catch (error) {
        console.error(error);
      }
    },
    async loadAllJobs() {
      this.isJobsLoading = true;
      const response = await this.$apollo.query({
        query: gql`
          query getJobs($product_name: ProductName!) {
            jobs: getJobs(product_name: $product_name) {
              _id
              name
              status
              created_at
              tasks {
                _id
                status
                input
              }
            }
          }
        `,
        variables: {
          product_name: this.$product.id,
        },
      });
      const jobs = {};
      const options = [];
      const selected = {};
      response.data.jobs.filter((job) => job.status === 'completed' && job.tasks[0].status === 'success' && job.tasks[0].input.workflow === 'simulation').forEach((job) => {
        jobs[job._id] = job;
        options.push({
          value: job._id,
          label: job.name,
          created: this.$filters.formatDate(job.created_at),
          coater: this.drumOptions[job.tasks[0].input.drum].label,
          rpm: job.tasks[0].input.drumRotationSpeed,
          load: job.tasks[0].input.mass,
        });
        selected[job._id] = false;
      });
      this.jobsAll = jobs;
      this.jobsOptions = options;
      this.jobsSelection.value = selected;
      this.isJobsLoading = false;
    },
    async loadPlots(job_id, task_id) {
      const response = await this.$apollo.query({
        query: gql`
          query getMedia($product_name: ProductName!, $job_id: ID!, $task_id: ID) {
            media: getMedia(product_name: $product_name, job_id: $job_id, task_id: $task_id) {
              contents {
                directory
                files
              }
            }
          }
        `,
        variables: {
          product_name: this.$product.id,
          job_id,
          task_id,
        },
      });
      const resultsDirectory = response.data.media[0].contents.filter((dir) => dir.directory === 'results')[0];
      console.log(resultsDirectory);
      const plots = {
        CoV: resultsDirectory.files.filter((file) => file.includes('CoV.png'))[0],
        spray: resultsDirectory.files.filter((file) => file.includes('spray_distribution.png'))[0],
        velocity: resultsDirectory.files.filter((file) => file.includes('velocity_distribution.png'))[0],
        velocity_sprayzone: resultsDirectory.files.filter((file) => file.includes('spray_zone_velocity.png'))[0],
      }
      this.plotsLoaded[job_id] = plots;
    },
    validateJob() {
      if (this.workflow.value === 'simulation') {
        // SETUP
        const setupValidates = this.title.ok && this.drumRotationSpeed.ok && this.tEnd.ok && this.tStep.ok;
        // TABLETS
        const tabletsValidates = this.loadingMass.ok && this.L1.ok && this.L2.ok 
        && this.L3.ok && this.density.ok && this.frictionPP.ok && this.frictionPW.ok
        // SPRAY
        const solventsValidates = this.nozzle.start.ok && this.nozzle.end.ok 
        && this.solvents.every((sol) => sol.material.ok && sol.volumeFraction.ok && sol.density.ok)
        && this.materials.every((mat) => mat.material.ok && mat.volumeFraction.ok && mat.density.ok)
        && this.nozzle.dirX.ok && this.nozzle.dirY.ok && this.nozzle.dirZ.ok
        && this.nozzle.axisX.ok && this.nozzle.axisY.ok && this.nozzle.axisZ.ok 
        && this.nozzle.majorAngle.ok && this.nozzle.minorAngle.ok && this.positions[0].posX.ok 
        && this.positions[0].posY.ok && this.positions[0].posZ.ok && this.positions[0].rate.ok 
        && this.nozzle.radiusMean.ok
        let totalVolumeFraction = 0;
        this.solvents.forEach((solvent) => {
          totalVolumeFraction += Number(solvent.volumeFraction.value);
        });
        this.materials.forEach((material) => {
          totalVolumeFraction += Number(material.volumeFraction.value);
        });
        let volumeValidates = Math.abs(totalVolumeFraction - 1.0) < 1e-3;
        this.volumeValidates = volumeValidates;
        const sprayValidates = solventsValidates && volumeValidates;
        if (this.workflow.value === 'simulation') {
          this.stepWarnings = [!setupValidates, !tabletsValidates,!sprayValidates];
        this.jobValidates = setupValidates && tabletsValidates && sprayValidates
        }
      }
      else { // comparison
        this.stepWarnings = [!this.title.ok, this.currentlySelectedJobs.length <= 1, this.currentlySelectedJobs.length > 5 ]
        this.jobValidates = this.title.ok && this.currentlySelectedJobs.length > 1 && this.currentlySelectedJobs.length <= 5;
      }
    },
    async submitJob() {
      this.isSubmitting = true;
      try {
        if (this.workflow.value === 'simulation') {
          const inputData = this.buildInputData();
          inputData.isTest = false; // true to run in test pool

          if (window.Cypress) {
            window.testSimulationInputs = inputData;
            // input data is saved to global window object for retrieval during E2E testing
          }

          await this.$apollo.mutate({
            mutation: gql`
            mutation addTabletCoaterSimulationJobAndTasks($product_name: ProductName!, $tasks: [TabletCoaterSimulationTaskIn]!, $job_name: String!) {
              job: addTabletCoaterSimulationJobAndTasks(product_name: $product_name, tasks: $tasks, job_name: $job_name) {
                _id
              }
            }`,
            variables: {
              product_name: this.$product.id,
              job_name: this.title.value,
              tasks: [inputData],
            },
          });
          this.$router.push('/home');
        }
        else { // comparison
          const inputData = { workflow: 'comparison' };
          inputData.title = this.title.value;
          inputData.jobIds = this.currentlySelectedJobs;

          await this.$apollo.mutate({
            mutation: gql`
            mutation addTabletCoaterComparisonJobAndTasks($product_name: ProductName!, $tasks: [TabletCoaterComparisonTaskIn]!, $job_name: String!) {
              job: addTabletCoaterComparisonJobAndTasks(product_name: $product_name, tasks: $tasks, job_name: $job_name) {
                _id
              }
            }`,
            variables: {
              product_name: this.$product.id,
              job_name: this.title.value,
              tasks: [inputData],
            },
          });
          this.$router.push('/home');
        }
      } catch (error) {
        console.error(error);
      }
    },
    increment() {
      this.positions = [
        ...this.positions,
        {
          posX: { value: "", ok: true },
          posY: { value: "", ok: true },
          posZ: { value: "", ok: true },
        },
      ];
    },
    addBlock(section, block) {
      // add a new solvent / material block
      console.log(block);
      section.push({ ...block });
    },
    removeBlock(section, index) {
      // remove solvent / material block
      section.splice(index, 1);
    },
    buildInputData() {
      const inputData = {};
      inputData.workflow = 'simulation';
      const insertNumberValue = (objData, key) => objData[key] = Number(this[key].value);

      inputData.title = this.title.value;
      insertNumberValue(inputData, 'tEnd');
      insertNumberValue(inputData, 'tStep');      
      insertNumberValue(inputData, 'drum');
      insertNumberValue(inputData, 'drumRotationSpeed');
      inputData.mass = Number(this.loadingMass.value);
      insertNumberValue(inputData, 'L1');
      insertNumberValue(inputData, 'L2');
      insertNumberValue(inputData, 'L3');
      insertNumberValue(inputData, 'density');
      insertNumberValue(inputData, 'frictionPP');
      insertNumberValue(inputData, 'frictionPW');
      insertNumberValue(inputData, 'stiffnessPP');
      insertNumberValue(inputData, 'stiffnessPW');
      insertNumberValue(inputData, 'restitutionPP');
      insertNumberValue(inputData, 'restitutionPW');
      //insertNumberValue(inputData, 'sprayDensity');

      inputData.solvents = [];
      this.solvents.forEach((solvent) => {
        inputData.solvents.push({
          volumeFraction: Number(solvent.volumeFraction.value),
          name: solvent.material.value,
          density: Number(solvent.density.value),
        });
      });

      inputData.materials = [];
      this.materials.forEach((material) => {
        inputData.materials.push({
          volumeFraction: Number(material.volumeFraction.value),
          name: material.material.value,
          density: Number(material.density.value),
        });
      });

      inputData.nozzle = {};
      for (const [key, value] of Object.entries(this.nozzle)) {
        if (key !== 'expertMode')
          inputData.nozzle[key] = Number(value.value);
      }

      inputData.positions = [];
      this.positions.forEach((position) => {
        inputData.positions.push({
          posX: Number(position.posX.value),
          posY: Number(position.posY.value),
          posZ: Number(position.posZ.value),
          rate: Number(position.rate.value),
        });
      });
      console.log(inputData)
      return inputData;
    },
  },
  watch: {
    activeStep(newStep) {
      if (newStep > this.highestVisitedStep)
        this.highestVisitedStep = newStep;
      if (this.workflow.value === 'comparison' && newStep === 1) {
        this.loadAllJobs();
        this.stepWarnings = [false, false];
      }
      if (newStep === this.stepLabels.length - 1) {
        this.validateJob();
      }
      window.scrollTo(0,0);
    },
    workflow(newWorkflow) {
      console.log(newWorkflow);
      if (newWorkflow.value === 'simulation') {
        this.tokenCounter = 9;
      } else {
        this.tokenCounter = 1;
      }
    },
    jobsSelection: {
      deep: true,
      handler(newSelected) {
        Object.entries(newSelected.value).forEach(([id, isSelected]) => {
          if (isSelected && !(id in this.plotsLoaded)) {
            this.loadPlots(id, this.jobsAll[id].tasks[0]._id);
          }
        });
      },
    },
    solvents: {
      deep: true,
      handler() {
        this.solvents.forEach((solvent) => {
          if (solvent.material.value !== 'custom') {
            this.solventOptions.forEach((option) => {
              if (solvent.material.value == option.value) {
                solvent.density.value = option.density;
              }
            });
          }
        });
      },
    },
  },
};
</script>

<style scoped>
.stepIndicatorMargins {
  margin-top: 1em;
  margin-bottom: 3em;
}
.mt {
  margin-top: 1em;
}
.title-breathe {
  margin: 2em 0 1em 0;
}
.plot-container {
  display: flex;
  flex-wrap: wrap;
}
.plot {
  max-width: 50%;
  display: inline-block;
}
.plot-sim-title {
  margin-left: 0.75em;
}
.table-checkbox {
  padding: 0.75rem;
}
</style>
