<template>
  <div class="section">
    <div class="container ist-container">
      <template v-if="task == null">
        <h1 class="title">
          <Loader :is-loading="true" />
          Results
        </h1>
      </template>
      <template v-else>
        <div class="buttons is-pulled-right">
          <EditJobName
            :name="job.name"
            @jobNameEdit="handleJobNameEdit"
          />
          <ReportModal
            v-if="job.status == 'completed' && task.status === 'success'"
            :is-loading="isPdfLoading"
            @generateReport="downloadPdf"
          />
          <!-- compare button -->
          <CompareModal
            v-if="job.status == 'completed' && task.status === 'success' && task.input.workflow === 'simulation'"
            :selectedJob="job"
          />
        </div>
        <div class="is-flex is-align-content-center is-align-items-center">
          <h1
            class="title is-3 no-space-bottom"
            style="display: inline-block;"
            data-cy="job-title"
          >
            {{ job.name }}
          </h1>
          <span
              class="tag is-medium has-text-weight-normal"
              style="margin-left: 1em; vertical-align: center;"
              :class="{ 'is-warning': task.status !== 'success' }"
              data-cy="task-status"
            >{{ task.status }}
          </span>
        </div>
        <div class="level">
          <div class="level-left">
            <div class="level-item">
              <p>started: {{ $filters.formatDate(task.started_at) }}</p>
            </div>
            <div v-if="task.status === 'running'" class="level-item">
              <p>
                progress: {{ progressMessage }}
                <font-awesome-icon
                  icon="info-circle"
                  class="help-icon"
                  title="updates every 30 minutes"
                />
              </p>
            </div>
            <div class="level-item">
              <p>completed: {{ $filters.formatDate(task.finished_at) }}</p>
            </div>
          </div>
        </div>
        <InputsSummary v-if="input != null && input.workflow === 'comparison'">
          <div class="table-container" style="margin-top: 1em">
            <table class="table ist-table is-striped">
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Coater</th>
                  <th>RPM</th>
                  <th>Load (kg)</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="jobId in output.jobIds" :key="jobId">
                  <td class="left">
                    <router-link
                      :to="`/jobs/${jobId}`"
                      class="is-primary"
                    >
                      {{ output[jobId].title }}
                    </router-link>
                  </td>
                  <td class="left">{{ coaterData[output[jobId].drum].name }}</td>
                  <td>{{ output[jobId].drumRotationSpeed }}</td>
                  <td>{{ output[jobId].mass }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </InputsSummary>
        <InputsSummary v-if="input != null && input.workflow === 'simulation'">
          <h3 class="title is-5" style="margin-top: 1.5em">Setup</h3>
          <SummaryItem label="Coater" :value="coaterData[input.drum].name" />
          <SummaryItem label="Coater rotation speed (rpm)" :value="input.drumRotationSpeed" />
          <SummaryItem label="Simulation duration (s)" :value="input.tEnd" />
          <SummaryItem label="Time step (µs)" :value="input.tStep" />

          <!--check drum--> 
          <h3 class="title is-5" style="margin-top: 1.5em">Tablets</h3>
          <div class="table-container">
            <table
              id="InteractionsTable"
              class="table ist-table is-striped"
              style="margin-top: 1.5em"
            >
              <thead>
                <tr>
                  <th>Band thickness L1 (mm)</th>
                  <th>Total thickness L2 (mm)</th>
                  <th>Band diameter L3 (mm)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ input.L1 }}</td>
                  <td>{{ input.L2 }}</td>
                  <td>{{ input.L3 }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <SummaryItem label="Load (kg)" :value="input.mass"/> <!-- x-particles -->
          <SummaryItem label="Particle density (g/L)" :value="input.density" />
          <div class="table-container">
            <table
              id="InteractionsTables"
              class="table ist-table is-striped"
              style="margin-top: 1.5em"
            >
              <thead>
                <tr>
                  <th>Interaction Type</th>
                  <th>Sliding friction (-)</th>
                  <th>Stiffness (N/m)</th>
                  <th>Restitution (-)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Particle-Particle</td>
                  <td>{{ input.frictionPP }}</td>
                  <td >
                    {{ input.stiffnessPP }}
                  </td>
                  <td>
                    {{ input.restitutionPP }}
                  </td>
                </tr>
                <tr>
                  <td>Particle-Wall</td>
                  <td>{{ input.frictionPW }}</td>
                  <td>
                    {{ input.stiffnessPW }}
                  </td>
                  <td>
                    {{ input.restitutionPW }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <h3 class="title is-5" style="margin-top: 1.5em">Spray</h3>
          <SummaryItem label="Start (s)" :value="input.nozzle.start" />
          <SummaryItem label="End (s)" :value="input.nozzle.end" />
          <div class="table-container">
            <table
              id="SolventsTable"
              class="table ist-table is-striped"
              style="margin-top: 1.5em"
            >
              <thead>
                <tr>
                  <th>Volume fraction</th>
                  <th>Material</th>
                  <th>Density (g/L)</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="material in input.materials" :key="material">
                  <td>{{ material.volumeFraction }}</td>
                  <td>{{ material.name }}</td>
                  <td>{{ material.density }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="table-container">
            <table
              id="SolventsTable"
              class="table ist-table is-striped"
              style="margin-top: 1.5em"
            >
              <thead>
                <tr>
                  <th>Volume fraction</th>
                  <th>Material</th>
                  <th>Density (g/L)</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="solvent in input.solvents" :key="solvent">
                  <td>{{ solvent.volumeFraction }}</td>
                  <td>{{ solvent.name }}</td>
                  <td>{{ solvent.density }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="table-container">
            <table
              id="DirectionTable"
              class="table ist-table is-striped"
              style="margin-top: 1.5em"
            >
              <thead>
                <tr>
                  <th></th>
                  <th>X</th>
                  <th>Y</th>
                  <th>Z</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Nozzle Direction</td>
                  <td>{{ input.nozzle.dirX }}</td>
                  <td>{{ input.nozzle.dirY }}</td>
                  <td>{{ input.nozzle.dirZ }}</td>
                </tr>
                <tr>
                  <td>Major Axis</td>
                  <td>{{ input.nozzle.axisX }}</td>
                  <td>{{ input.nozzle.axisY }}</td>
                  <td>{{ input.nozzle.axisZ }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <SummaryItem label="Major opening angle (°)" :value="input.nozzle.majorAngle" />
          <SummaryItem label="Minor opening angle (°)" :value="input.nozzle.minorAngle" />
          <div class="table-container">
            <table
              id="NozzlePositions"
              class="table ist-table is-striped"
              style="margin-top: 1.5em"
            >
              <thead>
                <tr>
                  <th></th>
                  <th>X (m)</th>
                  <th>Y (m)</th>
                  <th>Z (m)</th>
                  <th>Spray Rate (g/min)</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(nozzle, index) in input.positions" :key="nozzle">
                  <td>Nozzle {{ index + 1 }}</td>
                  <td>{{ nozzle.posX }}</td>
                  <td>{{ nozzle.posY }}</td>
                  <td>{{ nozzle.posZ }}</td>
                  <td>{{ nozzle.rate }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <SummaryItem
            label="Mean droplet radius (µm)"
            :value="
              input.nozzle.radiusMean + ' &plusmn; ' + input.nozzle.radiusSigma
            "
          />
          <SummaryItem
            label="Droplet radius range (µm)"
            :value="input.nozzle.radiusMin + ' - ' + input.nozzle.radiusMax"
          />
        </InputsSummary>
        <template v-if="resultsReady && input.workflow === 'simulation'">
          <h3 class="title is-5" style="margin-top: 1.5em">Coefficient of Inter-Tablet Coating Variability</h3>
          <p>
            The coefficient of inter-tablet coating variability (CoV) is a widely used quality criterion for a coating process.
            The definition of the CoV is the standard deviation σmc of the sprayed coating mass divided by the mean of the coating mass µmc.
          </p>
          <p>
            After <span class="has-text-weight-bold">{{ output.CoV_after }} s</span> the CoV is <span class="has-text-weight-bold">{{ output.CoV_end }}</span>.
            The CoV declines linearly over time when plotted over a double logarithmic plot, as seen below. 
          </p>
          <figure class="image figure-with-space">
            <img :src="plots.CoV" title="coefficient of variation">
          </figure>
          <h3 class="title is-5" style="margin-top: 1.5em;">Spray Mass Distribution</h3>
          <p>
            The plot above shows the evolution of the spray mass distribution over time.
            The red line shows the distribution after the first coating event, and the black line shows the coating mass distribution at the last simulated time step.
            The mean coating mass in the last time step is <span class="has-text-weight-bold">{{ output.spray_mean }}</span> and the standard deviation is <span class="has-text-weight-bold">{{ output.spray_std }}</span>&nbsp;x10<sup>-6</sup> kg.
          </p>          
          <figure class="image figure-with-space">
            <img :src="plots.spray" title="spray">
          </figure>
          <h3 class="title is-5" style="margin-top: 1.5em;">Velocity Distributions</h3>
          <p>
            The velocity distribution in the spray zone is essential for the process's performance.
            Uniform velocity distribution in the spray zone results in a uniform spray residence time distribution and a good coating quality due to the homogenous distribution of coating mass per pass. 
          </p>
          <figure class="image figure-with-space">
            <img :src="plots.velocity" title="velocity distribution">
          </figure>
          <p>
            Higher velocities lead to shorter single-visit residence times, better mixing in the spray zone, and an increased probability of tablet and coating defects due to abrasion.
            In contrast, low tablet velocities increase the risk of over-wetting the tablet, which is the leading cause of tablets sticking together and other defects.
          </p>
          <p>
            The plots in this section show the velocity distribution for all time steps in light grey and the time average result in black.
            The mean velocity is <span class="has-text-weight-bold">{{ output.velocity_distribution_mean}} &plusmn; {{ output.velocity_distribution_std }} m/s</span> overall and <span class="has-text-weight-bold">{{ output.spray_zone_velocity_mean}} &plusmn; {{ output.spray_zone_velocity_std }} m/s </span> inside the spray zone.
          </p>
          <figure class="image figure-with-space">
            <img :src="plots.velocity_sprayzone" title="spray region velocity distribution">
          </figure>
          <h3 class="title is-5" style="margin-top: 2em; margin-bottom: 1em;">Coating Visualisation</h3>
          <!--
          <video controls>
            <source src="../assets/coater_6s.mp4">
          </video>
          -->
          <Spraying
            :tablets="vtp.tablets"
            :coater="vtp.coater"
            :tabletSize="tabletSize"
            :tabletStl="tabletStl"
            :nozzleInfo="input.nozzle"
            :nozzlePositions="input.positions"
          />
        </template>
        <template v-if="resultsReady && input.workflow === 'comparison'">
          <h2 class="title is-4 title-breathe">Comparison</h2>
          <h3 class="title is-6">Coefficient of Inter-Tablet Coating Variability</h3>
          <figure class="image figure-with-space">
            <img :src="plots.CoV">
          </figure>
          <h3 class="title is-6">Tablets Sprayed</h3>
          <figure class="image figure-with-space">
            <img :src="plots.spray">
          </figure>
          <h3 class="title is-6">Velocity Distribution in Coater</h3>
          <figure class="image figure-with-space">
            <img :src="plots.velocity">
          </figure>
          <h3 class="title is-6">Velocity Distribution in Spray Zone</h3>
          <figure class="image figure-with-space">
            <img :src="plots.velocity_sprayzone">
          </figure>
          <h2 class="title is-4 title-breathe">Simulations Side-by-side</h2>
          <h3 class="title is-5 title-breathe">Coefficient of Inter-Tablet Coating Variability</h3>
          <div class="plot-container">
            <figure v-for="jobId in input.jobIds" :key="jobId" class="plot">
              <p class="plot-sim-title">{{output[jobId].title}}</p>
              <img :src="plots[jobId].CoV">
            </figure>
          </div>
          <h3 class="title is-5 title-breathe">Tablets Sprayed</h3>
          <div class="plot-container">
            <figure v-for="jobId in input.jobIds" :key="jobId" class="plot">
              <p class="plot-sim-title">{{output[jobId].title}}</p>
              <img :src="plots[jobId].spray">
            </figure>
          </div>
          <h3 class="title is-5 title-breathe">Velocity Distribution in Coater</h3>
          <div class="plot-container">
            <figure v-for="jobId in input.jobIds" :key="jobId" class="plot">
              <p class="plot-sim-title">{{output[jobId].title}}</p>
              <img :src="plots[jobId].velocity">
            </figure>
          </div>
          <h3 class="title is-5 title-breathe">Velocity Distribution in Spray Zone</h3>
          <div class="plot-container">
            <figure v-for="jobId in input.jobIds" :key="jobId" class="plot">
              <p class="plot-sim-title">{{output[jobId].title}}</p>
              <img :src="plots[jobId].velocity_sprayzone">
            </figure>
          </div>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import EditJobName from 'ist-skeleton-vue/src/components/Results/EditJobName.vue';
//import FieldHelp from 'ist-skeleton-vue/src/components/Simulation/FieldHelp.vue';
import InputsSummary from 'ist-skeleton-vue/src/components/Results/InputsSummary.vue';
import Loader from 'ist-skeleton-vue/src/components/Loader.vue';
import ReportModal from 'ist-skeleton-vue/src/components/Results/ReportModal.vue';
import CompareModal from '../components/CompareModal.vue';
import Spraying from '../components/VtkScenes/Spraying.vue';
import SummaryItem from 'ist-skeleton-vue/src/components/Simulation/SummaryItem.vue';
import coaterData from "../assets/coaters.json";


export default {
  components: {
    EditJobName,
    //FieldHelp,
    InputsSummary,
    Loader,
    ReportModal,
    SummaryItem,
    Spraying,
    CompareModal
  },
  computed: {
    tabletSize() {
      if (this.input != null) return [this.input.L1, this.input.L2, this.input.L3];
      return null;
    },
    progressMessage() {
      if (!this.progressReady) return 'n/a';
      return `${this.progressSimulationTime} / ${this.input.tEnd} s`;
    },
  },
  data() {
    return {
      // job data
      job: null,
      task: null,
      input: null,
      output: null,
      // media data
      baseUrl: null,
      containerSAS: null,
      plots: {},
      vtp: {
        tablets: [], // URLs for tablet files
        coater: []
      },
      coaterData,
      tabletStl: null,
      // progress data during running simulation
      progressReady: false,
      progress: {},
      progressSimulationTime: null,
      // view data
      resultsReady: false,
      // PDF Report
      isPdfLoading: false,
    };
  },
  async mounted() {
    try {
      await this.fetchJob();
      await this.fetchMediaInfo();
      if (this.task.status === 'running') {
        await this.fetchProgressJson();
      }
      if (this.task.status === 'success') {
        const fetchOutputJson = this.fetchOutputJson();
        const fetchMedia = this.fetchMedia();
        await Promise.all([fetchMedia, fetchOutputJson]);
        this.resultsReady = true;
      }
    } catch (error) {
      console.error(error);
    }
  },
  methods: {
    async fetchJob() {
      const response = await this.$apollo.query({
        query: gql`
          query getJob($product_name: ProductName!, $job_id: ID!) {
            job: getJob(product_name: $product_name, job_id: $job_id) {
              _id
              name
              status
              created_at
              tasks {
                id: _id
                input
                status
                started_at
                finished_at
              }
            }
          }
        `,
        variables: {
          product_name: this.$product.id,
          job_id: this.$route.params.id,
        },
      });
      this.job = response.data.job;
      [this.task] = this.job.tasks;
      this.input = this.task.input;
    },
    async fetchMediaInfo() {
      const response = await this.$apollo.query({
        query: gql`
          query getAccessInfo($product_name: ProductName!, $job_id: ID!, $task_id: ID) {
            info: getAccessInfo(product_name: $product_name, job_id: $job_id, task_id: $task_id) {
              baseURL
              containerSAS
            }
          }
        `,
        variables: {
          product_name: this.$product.id,
          job_id: this.$route.params.id,
          task_id: this.task.id,
        },
      });
      this.baseUrl = response.data.info.baseURL;
      this.containerSAS = response.data.info.containerSAS;
    },
    async fetchMedia() {
      const response = await this.$apollo.query({
        query: gql`
          query getMedia($product_name: ProductName!, $job_id: ID!, $task_id: ID) {
            media: getMedia(product_name: $product_name, job_id: $job_id, task_id: $task_id) {
              contents {
                directory
                files
              }
            }
          }
        `,
        variables: {
          product_name: this.$product.id,
          job_id: this.$route.params.id,
          task_id: this.task.id,
        },
      });
      // plots
      if (this.input.workflow === 'simulation') {
        const resultsDirectory = response.data.media[0].contents.filter((dir) => dir.directory === 'results')[0];
        this.plots.CoV = resultsDirectory.files.filter((file) => file.includes('CoV.png'))[0];
        this.plots.spray = resultsDirectory.files.filter((file) => file.includes('spray_distribution.png'))[0];
        this.plots.velocity = resultsDirectory.files.filter((file) => file.includes('velocity_distribution.png'))[0];
        this.plots.velocity_sprayzone = resultsDirectory.files.filter((file) => file.includes('spray_zone_velocity.png'))[0];
        // vtp files
        const vtpDirectory = response.data.media[0].contents.filter((dir) => dir.directory === 'results/results')[0];
        this.vtp.tablets = vtpDirectory.files.filter((file) => file.includes('PS.vtp'));
        this.vtp.coater = vtpDirectory.files.filter((file) => file.includes('coater.vtp'));
        this.tabletStl = this.resultsURL('tablet.stl');
      }
      else { // comparison
        // comparison plots
        const resultsDirectory = response.data.media[0].contents.filter((dir) => dir.directory === 'results')[0];
        this.plots.CoV = resultsDirectory.files.filter((file) => file.includes('CoV.png'))[0];
        this.plots.spray = resultsDirectory.files.filter((file) => file.includes('spray_distribution.png'))[0];
        this.plots.velocity = resultsDirectory.files.filter((file) => file.includes('velocity_distribution.png'))[0];
        this.plots.velocity_sprayzone = resultsDirectory.files.filter((file) => file.includes('spray_zone_velocity.png'))[0];
        this.input.jobIds.forEach((jobId) => {
          const jobPlotDirectory = `results/${jobId}`;
          const resultsDirectory = response.data.media[0].contents.filter((dir) => dir.directory === jobPlotDirectory)[0];
          const jobPlots = {
            CoV: resultsDirectory.files.filter((file) => file.includes('CoV.png'))[0],
            spray: resultsDirectory.files.filter((file) => file.includes('spray_distribution.png'))[0],
            velocity: resultsDirectory.files.filter((file) => file.includes('velocity_distribution.png'))[0],
            velocity_sprayzone: resultsDirectory.files.filter((file) => file.includes('spray_zone_velocity.png'))[0]
          }
          this.plots[jobId] = jobPlots;
        });
      }
    },

    resultsURL(filename) {
      return `${this.baseUrl}results/${filename}?${this.containerSAS}`;
    },

    async fetchOutputJson() {
      const output = this.resultsURL('output.json');
      const response = await fetch(output);
      this.output = await response.json();
    },

    async fetchProgressJson() {
      try {
        const progress = this.resultsURL('progress.json');
        const response = await fetch(progress);
        this.progress = await response.json();
        this.progressSimulationTime = this.progress.checkpoints[this.progress.checkpoints.length - 1].sim_time;
        this.progressReady = true;
      } catch (error) {
        this.progressReady = false;
      }
    },

    handleJobNameEdit(newJobName) {
      this.job.name = newJobName;
    },

    buildPdfContentsForComparison() {
      // shortcut
      const { input,output} = this

      // comparison inputs
      const inputs = [];
      inputs.push({
        key: 'simulation-inputs',
        type: 'table',
        data: {
          header: ['Title', 'Coater', 'RPM', 'Load (kg)'],
          rows: output.jobIds.map((jobId) => [ output[jobId].title, this.coaterData[output[jobId].drum].name, output[jobId].drumRotationSpeed, output[jobId].mass ] ),
          caption: 'Overview of the simulation paramaters.',
        },
      });

      // comparison results
      const results = [];
      //Comparison
      results.push({ type: 'string', data: '## Comparison' });
      results.push({
        type: 'figure',
        data: {
          caption: 'Coefficient of inter-tablet coating variability over time.',
          url: this.plots.CoV,
          limitHeight: false,
        },
      });
      results.push({
        type: 'figure',
        data: {
          caption: 'Spray mass distribution over time.',
          url: this.plots.spray,
          limitHeight: false,
        },
      });
      results.push({
        type: 'figure',
        data: {
          caption: 'Velocity distribution.',
          url: this.plots.velocity,
          limitHeight: false,
        },
      });
      results.push({
        type: 'figure',
        data: {
          caption: 'Velocity distribution in the spray zone.',
          url: this.plots.velocity_sprayzone,
          limitHeight: false,
        },
      });

      //Simulation side by side
      results.push({ type: 'string', data: '## Coefficient of inter-tablet coating variability over time' });
      input.jobIds.map((jobId) => {
        results.push({
          type: 'figure',
          data: {
            caption: `CoV of ${output[jobId].title}`,
            url: this.plots[jobId].CoV,
            limitHeight: false,
          }
        })
      })
      results.push({ type: 'string', data: '## Spray mass distribution over time' });
      input.jobIds.map((jobId) => {
        results.push({
          type: 'figure',
          data: {
            caption: `Spray mass distribution of ${output[jobId].title}`,
            url: this.plots[jobId].spray,
            limitHeight: false,
          }
        })
      })
      results.push({ type: 'string', data: '## Velocity distribution' });
      input.jobIds.map((jobId) => {
        results.push({
          type: 'figure',
          data: {
            caption: `Velocity Distribution of ${output[jobId].title}`,
            url: this.plots[jobId].velocity,
            limitHeight: false,
          }
        })
      })
      results.push({ type: 'string', data: '## Velocity distribution in the spray zone' });
      input.jobIds.map((jobId) => {
        results.push({
          type: 'figure',
          data: {
            caption: `Velocity Distribution in the spray zone of ${output[jobId].title}`,
            url: this.plots[jobId].velocity_sprayzone,
            limitHeight: false,
          }
        })
      })

      return { inputs, results };
    },

    buildPdfContentsForSimulation() {
      // shortcut
      const { input } = this;
      const { output } = this;

      // simulation inputs
      const inputs = [];
      inputs.push({ type: 'string', data: '## Setup' });
      inputs.push({ type: 'string', data: `Coater: ${this.coaterData[input.drum].name}` });
      inputs.push({ type: 'string', data: `Coater rotation speed (rpm): ${input.drumRotationSpeed}` });
      inputs.push({ type: 'string', data: `Simulation duration (s): ${input.tEnd}` });
      inputs.push({ type: 'string', data: `Time step ($\\mu$s): ${input.tStep}` });
      inputs.push({ type: 'string', data: '## Tablets' });
      inputs.push({
        key: 'tablet-shape-table',
        type: 'table',
        data: {
          header: ['Band thickness L1 (mm)', 'Total thickness L2 (mm)', 'Band diameter L3 (mm)'],
          rows: [[input.L1, input.L2, input.L3]],
          caption: 'Tablet shape parameters.',
        },
      });
      inputs.push({ type: 'string', data: `Load (kg): ${input.mass}` });
      inputs.push({ type: 'string', data: `Particle density (g/L): ${input.density}` });
      inputs.push({
        key: 'interactions-table',
        type: 'table',
        data: {
          header: ['Interaction', 'Sliding friction (-)', 'Stiffness (N/m)', 'Restitution (-)'],
          rows: [
            ['Particle-particle', input.frictionPP, input.stiffnessPP, input.restitutionPP],
            ['Particle-wall', input.frictionPW, input.stiffnessPW, input.restitutionPW],
          ],
          caption: 'Particle-particle and particle-wall interaction parameters.',
        },
      });
      inputs.push({ type: 'string', data: '## Spray' });
      inputs.push({ type: 'string', data: `Spray start (s): ${input.nozzle.start}` });
      inputs.push({ type: 'string', data: `Spray end (s): ${input.nozzle.end}` });
      inputs.push({
        key: 'solvents-table',
        type: 'table',
        data: {
          header: ['Solvent', 'Volume fraction', 'Density (g/L)'],
          rows: input.solvents.map((sol) => [sol.name, sol.volumeFraction, sol.density]),
          caption: 'Spray constituents: solvents.',
        },
      });
      inputs.push({
        key: 'materials-table',
        type: 'table',
        data: {
          header: ['Material', 'Volume fraction', 'Density (g/L)'],
          rows: input.materials.map((sol) => [sol.name, sol.volumeFraction, sol.density]),
          caption: 'Spray constituents: solid parts.',
        },
      });
      inputs.push({ type: 'string', data: `Mean droplet radius ($\\mu$m): ${input.nozzle.radiusMean} $\\pm$ ${input.nozzle.radiusSigma}` });
      inputs.push({ type: 'string', data: `Droplet radius range ($\\mu$m): ${input.nozzle.radiusMin} - ${input.nozzle.radiusMax}` });
      inputs.push({ type: 'string', data: '## Nozzles' });
      inputs.push({ type: 'string', data: `Nozzle direction: (${input.nozzle.dirX}, ${input.nozzle.dirY}, ${input.nozzle.dirZ})` });
      inputs.push({ type: 'string', data: `Major axis: (${input.nozzle.axisX}, ${input.nozzle.axisY}, ${input.nozzle.axisZ})` });
      inputs.push({ type: 'string', data: `Major opening angle (°): ${input.nozzle.majorAngle}` });
      inputs.push({ type: 'string', data: `Minor opening angle (°): ${input.nozzle.minorAngle}` });
      inputs.push({
        key: 'nozzles-table',
        type: 'table',
        data: {
          header: ['', 'X (m)', 'Y (m)', 'Z (m)', 'Spray rate (g/min)'],
          rows: input.positions.map((nozzle, index) => [`Nozzle ${index + 1}`, nozzle.posX, nozzle.posY, nozzle.posZ, nozzle.rate]),
          caption: 'Nozzle positions and corresponding spray rates.',
        },
      });

      // simulation results
      const results = [];
      results.push({ type: 'string', data: '## Coefficient of Inter-Tablet Coating Variability' });
      results.push({
        type: 'figure',
        data: {
          caption: 'Coefficient of inter-tablet coating variability over time.',
          url: this.plots.CoV,
          limitHeight: false,
        },
      });
      results.push({ type: 'string', data: `The coefficient of inter-tablet coating variability (CoV) is a widely used quality criterion for a coating process. The definition of the CoV is the standard deviation $\\sigma$mc of the sprayed coating mass divided by the mean of the coating mass $\\mu$mc.\n\nAfter **${output.CoV_after} s** the CoV is **${output.CoV_end}**. The CoV declines linearly over time when plotted over a double logarithmic plot, as seen in the figure.` });
      results.push({ type: 'string', data: '## Spray Mass Distribution' });
      results.push({
        type: 'figure',
        data: {
          caption: 'Spray mass distribution over time.',
          url: this.plots.spray,
          limitHeight: false,
        },
      });
      results.push({ type: 'string', data: `The plot above shows the evolution of the spray mass distribution over time. The red line shows the distribution after the first coating event, and the black line shows the coating mass distribution at the last simulated time step. The mean coating mass in the last time step is **${output.spray_mean}** and the standard deviation is **${ output.spray_std }
      x$10^{-6}$ kg**.` });
      results.push({ type: 'string', data: '## Velocity Distributions' });
      results.push({
        type: 'figure',
        data: {
          caption: 'Velocity distribution.',
          url: this.plots.velocity,
          limitHeight: false,
        },
      });
      results.push({
        type: 'figure',
        data: {
          caption: 'Velocity distribution in the spray zone.',
          url: this.plots.velocity_sprayzone,
          limitHeight: false,
        },
      });
      results.push({ type: 'string', data: `The velocity distribution in the spray zone is essential for the process's performance. Uniform velocity distribution in the spray zone results in a uniform spray residence time distribution and a good coating quality due to the homogenous distribution of coating mass per pass.\n\nHigher velocities lead to shorter single-visit residence times, better mixing in the spray zone, and an increased probability of tablet and coating defects due to abrasion. In contrast, low tablet velocities increase the risk of over-wetting the tablet, which is the leading cause of tablets sticking together and other defects. The plots above shows the velocity distribution for all time steps in light grey and the time average result in black. The mean velocity is **${ output.velocity_distribution_mean}** &plusmn; **${ output.velocity_distribution_std } m/s** overall and **${ output.spray_zone_velocity_mean}** &plusmn; **${ output.spray_zone_velocity_std } m/s** inside the spray zone.` });
      
      return { inputs, results };
    },

    async downloadPdf(userContent) {
      this.isPdfLoading = true;

      // refresh token for images
      await this.fetchMediaInfo();

      // user content (intro, discussion, conclusion)
      const userContentTokens = [];
      Object.keys(userContent).forEach((key) => {
        userContentTokens.push(
          { key, type: 'string', data: userContent[key] },
        );
      });

      const {inputs, results} = this.input.workflow === "simulation" ?  this.buildPdfContentsForSimulation() : this.buildPdfContentsForComparison()
      // job content (inputs, outputs, plots)
      
      // request PDF
      const response = await this.$api.call('POST', 'api/pdf-export-func', {
        header: {
          Accept: 'application/json',
        },
        responseType: 'blob',
        data: {
          template: 'tabletcoater',
          tokens: [
            ...userContentTokens,
            { key: 'title', type: 'string', data: this.job.name },
            { key: 'date', type: 'string', data: this.$filters.formatDate(this.task.finished_at) },
            { key: 'inputData', type: 'stack', data: inputs },
            { key: 'results', type: 'stack', data: results },
          ],
        },
      });

      // PDF download for user
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      link.setAttribute('download', 'SimTabletCoater_Report.pdf');
      document.body.appendChild(link);
      link.click();
      link.remove();
      this.isPdfLoading = false;
    },
  },
};
</script>

<style scoped>
.no-space-bottom {
  margin-bottom: 0.1em !important;
}
.icon-with-margin {
  margin-right: 0.5em;
}
.tabs-with-space {
  margin-top: -0.5em;
  margin-bottom: 2em;
}
.tabs-with-space-above {
  margin-top: 2em;
  margin-bottom: 2em;
}
.figure-with-space {
  max-width: 50%;
  margin: auto;
}
p {
  margin-top: 0.75em;
}
.title-breathe {
  margin: 2em 0 1em 0;
}
.plot-container {
  display: flex;
  flex-wrap: wrap;
}
.plot {
  max-width: 50%;
  display: inline-block;
}
.plot-sim-title {
  margin-left: 0.75em;
}
</style>
