<template>
  <div class="size-container">
    <div ref="vtkContainer" />
    <div class="controls">
      <button class="button x-color" @click="resetCamera('x')">
        <font-awesome-icon icon="x" />
      </button>
      <button class="button y-color" @click="resetCamera('y')">
        <font-awesome-icon icon="y" />
      </button>
      <button class="button z-color" @click="resetCamera('z')">
        <font-awesome-icon icon="z" />
      </button>
      <button class="button" @click="takeScreenshot">
        <font-awesome-icon icon="camera" />
      </button>
    </div>
    <div class="animation-controls">
      <button class="button" @click="playbackStepBack">
        <font-awesome-icon icon="backward-step" />
      </button>
      <button v-if="!isPlaying" class="button" @click="playbackStart">
        <font-awesome-icon icon="play" />
      </button>
      <button v-else class="button" @click="playbackPause">
        <font-awesome-icon icon="pause" />
      </button>
      <button class="button" @click="playbackStepForward">
        <font-awesome-icon icon="forward-step" />
      </button>
      <button class="button" @click="playbackReset">
        <font-awesome-icon icon="repeat" />
      </button>
      <div class="slider">
        <input
          id="animation-slider"
          v-model.number="index"
          type="range"
          min="0"
          :max="tablets.length"
          @input="loadFrame"
        >
        <label for="animation-slider">
          {{ currentTime }} s
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import '@kitware/vtk.js/Rendering/Profiles/Geometry';
import '@kitware/vtk.js/Rendering/Profiles/Glyph';
import vtkActor from '@kitware/vtk.js/Rendering/Core/Actor';
import vtkFullScreenRenderWindow from '@kitware/vtk.js/Rendering/Misc/FullScreenRenderWindow';
import vtkMapper from "@kitware/vtk.js/Rendering/Core/Mapper";
import vtkXMLPolyDataReader from '@kitware/vtk.js/IO/XML/XMLPolyDataReader';
import vtkGlyph3DMapper from '@kitware/vtk.js/Rendering/Core/Glyph3DMapper';
import { OrientationModes, ScaleModes } from '@kitware/vtk.js/Rendering/Core/Glyph3DMapper/Constants';
import * as vtkMath from "@kitware/vtk.js/Common/Core/Math";
import vtkScalarBarActor from '@kitware/vtk.js/Rendering/Core/ScalarBarActor';
import vtkColorTransferFunction from "@kitware/vtk.js/Rendering/Core/ColorTransferFunction"
import vtkColorMaps from '@kitware/vtk.js/Rendering/Core/ColorTransferFunction/ColorMaps';
import { ColorMode, ScalarMode } from '@kitware/vtk.js/Rendering/Core/Mapper/Constants';
import vtkAxesActor from '@kitware/vtk.js/Rendering/Core/AxesActor';
import vtkOrientationMarkerWidget from '@kitware/vtk.js/Interaction/Widgets/OrientationMarkerWidget';
import vtkConeSource from "@kitware/vtk.js/Filters/Sources/ConeSource";
import vtkSTLReader from "@kitware/vtk.js/IO/Geometry/STLReader";

const up025 = (x) => Math.ceil(x * 4) / 4;
//const down025 = (x) => Math.floor(x * 2) / 2;

export default {
  props: ["coater", "tablets", "tabletStl", "tabletSize", "nozzleInfo", "nozzlePositions"],
  data() {
    return {
      // vtk.js
      fullScreenRenderer: null,
      renderer: null,
      renderWindow: null,
      // our data
      coaterReader: null,
      tabletsReader: null,
      tabletsMapper: null,
      lookupTable: null,
      axes: { actor: null, widget: null },
      // playback
      isPlaying: false,
      playbackFrameInterval: 50, // ms
      index: 0,
      min: 0,
      max: 1,
    };
  },
  computed: {
    currentTime() {
      const seconds = (this.index * this.playbackFrameInterval) / 1000; // ms to s
      return seconds.toFixed(3);
    },
  },
  methods: {
    async playbackStart() {
      this.isPlaying = true;
      while (this.isPlaying && this.index < this.tablets.length) {
        this.index += 1;
        await this.loadFrame();
      }
    },
    playbackStepBack() {
      if (this.index > 0) {
        this.index -= 1;
        this.loadFrame();
      }
    },
    playbackStepForward() {
      if (this.index < this.tablets.length - 1) {
        this.index += 1;
        this.loadFrame();
      }
    },
    playbackPause() {
      this.isPlaying = false;
    },
    async playbackReset() {
      this.isPlaying = false,
      this.index = 0;
      this.loadFrame();
    },
    async loadFrame() {
      const loadTablets = this.tabletsReader.setUrl(this.tablets[this.index]);
      const loadCoater = this.coaterReader.setUrl(this.coater[this.index]);
      const delay = new Promise((x) => setInterval(x, this.playbackFrameInterval));
      await Promise.all([loadTablets, loadCoater, delay]);

      this.tabletsMapper.setInputData(this.tabletsReader.getOutputData(), 0);
      this.renderWindow.render();
    },
    resetCamera(directionName) {
      const direction = {
        x: [1, 0, 0],
        y: [0, 1, 0],
        z: [0, 0, 1],
      }[directionName];
      
      const camera = this.renderer.getActiveCamera();
      camera.zoom(0.5);
      const position = camera.getPosition();
      const focalPoint = camera.getFocalPoint();
      const distance = Math.sqrt(
        vtkMath.distance2BetweenPoints(position, focalPoint)
      );
      camera.setPosition(
        focalPoint[0] + direction[0] * distance,
        focalPoint[1] + direction[1] * distance,
        focalPoint[2] + direction[2] * distance,
      );
      camera.setViewUp(direction);
      this.axes.widget.updateMarkerOrientation();
      this.renderer.resetCamera();
      this.renderWindow.render();
    },
    async takeScreenshot() {
      const image = await this.renderWindow.captureImages()[0];
      const response = await axios.get(image, { responseType: "blob" });
      var fileURL = window.URL.createObjectURL(new Blob([response.data]));
      var fileLink = document.createElement("a");
      fileLink.href = fileURL;
      fileLink.setAttribute("download", "InSilicoTrials_tabletcoater.png");
      document.body.appendChild(fileLink);
      fileLink.click();
    },
  },
  async mounted() {
    await this.$nextTick(); // wait for div with ref="vtkContainer" to exist

    // general vtk.js preparation
    this.fullScreenRenderer = vtkFullScreenRenderWindow.newInstance({
      rootContainer: this.$refs.vtkContainer,
    });
    this.renderer = this.fullScreenRenderer.getRenderer();
    this.renderWindow = this.fullScreenRenderer.getRenderWindow();

    // coater
    this.coaterReader = vtkXMLPolyDataReader.newInstance();
    await this.coaterReader.setUrl(this.coater[this.index]);
    this.coaterMapper = vtkMapper.newInstance();
    this.coaterMapper.setInputConnection(this.coaterReader.getOutputPort());

    const coaterActor = vtkActor.newInstance();
    coaterActor.setMapper(this.coaterMapper);
    coaterActor.getProperty().setOpacity(0.2);
    this.renderer.addActor(coaterActor);

    // colorbar
    this.lookupTable = vtkColorTransferFunction.newInstance()
    const preset = vtkColorMaps.getPresetByName('Plasma (matplotlib)');
    this.lookupTable.applyColorMap(preset);
    this.lookupTable.setMappingRange(0, 1);
    this.lookupTable.updateRange();
    
    // new glyphs from STL
    const stlSource = vtkSTLReader.newInstance();
    //await stlSource.setUrl(`${process.env.BASE_URL}tablet_X.stl`);
    await stlSource.setUrl(this.tabletStl);
    const glyphDataset = stlSource.getOutputData();

    // tablets
    this.tabletsReader = vtkXMLPolyDataReader.newInstance();
    await this.tabletsReader.setUrl(this.tablets[this.index]);
    //await this.tabletsReader.setUrl(`${process.env.BASE_URL}test.vtp`);
    this.tabletsMapper = vtkGlyph3DMapper.newInstance({
      lookupTable: this.lookupTable,
      useLookupTableScalarRange: true,
      colorMode: ColorMode.MAP_SCALARS,
      scalarMode: ScalarMode.USE_POINT_FIELD_DATA,
      colorByArrayName: 'speed',
      scalarVisibility: true,
      // scaling of tablets
      scaling: true,
      scaleMode: ScaleModes.SCALE_BY_CONSTANT,
      scaleFactor: 0.001,
      // orientation of tablets
      orient: true,
      orientationMode: OrientationModes.DIRECTION,
      orientationArray: 'direction',
    });

    // tablets as glyphs in vtkGlyph3DMapper
    this.tabletsMapper.setInputData(this.tabletsReader.getOutputData(), 0);
    this.tabletsMapper.setInputData(glyphDataset, 1); // Glyph
    const actor = vtkActor.newInstance();
    actor.setMapper(this.tabletsMapper);
    this.renderer.addActor(actor);

    // scalar bar
    const scalarBarActor = vtkScalarBarActor.newInstance();
    scalarBarActor.setDrawNanAnnotation(false);
    scalarBarActor.setAxisLabel("Velocity (m/s)");
    scalarBarActor.setVisibility(true);
    scalarBarActor.setScalarsToColors(this.tabletsMapper.getLookupTable());
    this.renderer.addActor(scalarBarActor);

    // axes marker (orientationWidget)
    this.axes.actor = vtkAxesActor.newInstance();
    this.axes.widget = vtkOrientationMarkerWidget.newInstance({
      actor: this.axes.actor,
      interactor: this.renderWindow.getInteractor(),
    });
    this.axes.widget.setEnabled(true);
    this.axes.widget.setViewportCorner(
      vtkOrientationMarkerWidget.Corners.BOTTOM_LEFT
    );
    this.axes.widget.setViewportSize(0.2);
    this.axes.widget.setMinPixelSize(100);
    this.axes.widget.setMaxPixelSize(300);

    // nozzles
    const cone = vtkConeSource.newInstance({
      radius: 0.02,
      height: 0.05,
    });
    cone.setResolution(50);
    cone.setDirection(
      this.nozzleInfo.dirX,
      this.nozzleInfo.dirY,
      this.nozzleInfo.dirZ,
    );
    this.nozzlePositions.forEach((nozzle) => {
      const coneMapper = vtkMapper.newInstance();
      const coneActor = vtkActor.newInstance();
      coneMapper.setInputConnection(cone.getOutputPort());
      coneActor.setMapper(coneMapper);
      coneActor.setPosition(
        nozzle.posX,
        nozzle.posY,
        nozzle.posZ,
      );
      this.renderer.addActor(coneActor);
    });

    // let's go
    this.renderer.resetCamera();
    this.renderWindow.render();
  },
  updated() {
    // tracking max range for scalarBar
    //const newMin = down025(this.tabletsReader.getOutputData().getPointData().getArrayByName("speed").getRange()[0]);
    //console.log(newMin)
    const newMax = up025(this.tabletsReader.getOutputData().getPointData().getArrayByName("speed").getRange()[1]);
    let changed = false;
    //if (newMin !== this.min) {
    //  changed = true;
    //  this.min = newMin;
    //}
    if (newMax > this.max) {
      changed = true;
      this.max = newMax;
    }
    if (newMax < this.max ) {
      this.max = this.max - 0.25;
    }
    /* else if (newMax <= this.max - 1) {
      changed = true;
      this.max = this.max - 0.5;
    } else if (newMax <= this.max - 0.5) {
      changed = true;
      this.max = this.max - 0.5;
    }
    */
    if (changed) {
      this.lookupTable.setMappingRange(this.min, this.max);
      this.lookupTable.updateRange();
    }
    //console.log("newMax", newMax, "this.max now", this.max);
  },
};
</script>

<style>
.size-container {
  position: relative;
  height: 800px;
}

.controls {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 1em;
  left: 1em;
}
.controls > button {
  margin-bottom: 0.25em;
}
.animation-controls {
  position: absolute;
  display: flex;
  flex-direction: row;
  bottom: 1em;
  left: 40%;
}
.animation-controls > button {
  margin-right: 0.25em;
}
.animation-controls > .slider {
  color: white;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
}

.playbackSlider {
  position: absolute;
  top: 1em;
  left: 5em;
  display: flex;
  flex-direction: column;
  align-items: center;
}

button.x-color {
  background-color: #ffc4c4;
}
button.y-color {
  background-color: #fff7ad;
}
button.z-color {
  background-color: #c2ffbe;
}

</style>