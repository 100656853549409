<template>
  <p class="buttons extra-space">
    <button
      v-if="modelValue>0"
      class="button is-primary with-space"
      @click="setStep(modelValue-1)"
    >
      <span class="icon is-small">
        <font-awesome-icon icon="chevron-left" />
      </span>
      <span>Previous</span>
    </button>
    <button
      v-if="modelValue + 1 < numSteps"
      class="button is-primary with-space"
      @click="setStep(modelValue+1)"
    >
      <span>Next</span>
      <span class="icon is-small">
        <font-awesome-icon icon="chevron-right" />
      </span>
    </button>
  </p>
</template>

<script>
export default {
  props: {
    modelValue: Number,
    numSteps: Number,
  },
  methods: {
    setStep(step) {
      this.$emit('update:modelValue', step);
    },
  },
};
</script>

<style scoped>
p.extra-space {
  margin-top: 1em;
}
</style>
