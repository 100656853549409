import 'ist-skeleton-vue/src/assets/fonts.scss';
import 'ist-skeleton-vue/src/assets/colors.scss';
import 'ist-skeleton-vue/src/assets/layout.scss';
import logo from 'ist-skeleton-vue/src/assets/ist_logo_white.png';
import image from '../assets/SimTabletCoater.png';

const product = {
  id: 'tabletcoater',
  name: 'SimTabletCoater',
  title: 'SimTabletCoater',
  subtitle:  'Shedding light on your tablet coating process',
  description: 'Find the sweet spot of the coating process for your tablets and drastically shorten an otherwise laborious parameter space exploration by manipulating parameters such as spray, mass of tablets, or coater rotation speed from the comfort of your web browser.',
  image,
  logo,
  features: [
    {
      icon: 'clipboard-check',
      title: 'Validated Model',
      description: 'Validated for investigation of the tablet coating process with industrially relevant settings',
    },
    {
      icon: 'cube',
      title: '3D Visualisation',
      description: 'See the simulation configuration and the simulation results in three dimensions',
    },
    {
      icon: 'thumbs-up',
      title: 'Easy to Use',
      description: 'Well-designed wizard that guides through the setup of the simulation step-by-step',
    },
  ],
};

export default product;
