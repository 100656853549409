<template>
  <div class="size-container">
    <div ref="vtkContainer" />
    <h1>g</h1>
    <div class="controls">
      <button class="button ml" @click="toggleRotation" :disabled="!rpm.ok">
        <font-awesome-icon :icon="isRotating === false ? 'play' : 'pause'"/>
      </button>
      <button class="button ml" style="background-color: #ffcfcf" @click="resetCamera('x')">
        <font-awesome-icon icon="x"/>
      </button>
      <button class="button ml" style="background-color: #fff8cf" @click="resetCamera('y')">
        <font-awesome-icon icon="y"/>
      </button>
      <button class="button ml" style="background-color: #d2ffcf" @click="resetCamera('z')">
        <font-awesome-icon icon="z"/>
      </button>
      <button class="button mt-1" @click="screenShot">
        <font-awesome-icon icon="camera"/>
      </button>
    </div>
  </div>
</template>

<script>
import "@kitware/vtk.js/Rendering/Profiles/Geometry";
import vtkActor from "@kitware/vtk.js/Rendering/Core/Actor";
import vtkFullScreenRenderWindow from "@kitware/vtk.js/Rendering/Misc/FullScreenRenderWindow";
import vtkMapper from "@kitware/vtk.js/Rendering/Core/Mapper";
import vtkAxesActor from "@kitware/vtk.js/Rendering/Core/AxesActor";
import vtkSTLReader from "@kitware/vtk.js/IO/Geometry/STLReader";
import vtkOrientationMarkerWidget from "@kitware/vtk.js/Interaction/Widgets/OrientationMarkerWidget";
import * as vtkMath from "@kitware/vtk.js/Common/Core/Math";
import vtkCubeSource from "@kitware/vtk.js/Filters/Sources/CubeSource";
import vtkConeSource from "@kitware/vtk.js/Filters/Sources/ConeSource";
import vtkArrowSource from "@kitware/vtk.js/Filters/Sources/ArrowSource";
import axios from "axios";
// import { unref } from '@vue/reactivity';

export default {
  data() {
    return {
      renderer: null,
      renderWindow: null,
      resolution: 10,
      representation: 1,
      orientation: 0,
      orientationIncrement: null,
      interval: undefined,
      intervalUpdateDelay: 1000.0 / 60, // 60 fps
      isRotating: false,
      actor: vtkActor.newInstance(),
      axes: null,
      orientationWidget: null,
      widget: null,
    };
  },
  props: ["fileObj", "dir", "pos", "rpm", "world"],
  watch: {
    orientation() {
      // const n = unref (this.orientation)
      this.actor.setOrientation(this.orientation, 0, 0);
      this.renderWindow.render();
    },
  },
  methods: {
    resetCamera(directionName) {
      const direction =
        directionName === "x"
          ? [1, 0, 0]
          : directionName === "y"
          ? [0, 1, 0]
          : [0, 0, 1];
      const camera = this.renderer.getActiveCamera();
      this.renderer.getActiveCamera().zoom(0.5);
      const focalPoint = camera.getFocalPoint();
      const position = camera.getPosition();
      const distance = Math.sqrt(
        vtkMath.distance2BetweenPoints(position, focalPoint)
      );
      camera.setPosition(
        focalPoint[0] + direction[0] * distance,
        focalPoint[1] + direction[1] * distance,
        focalPoint[2] + direction[2] * distance
      );
      camera.setViewUp(direction);
      this.orientationWidget.updateMarkerOrientation();
      this.widget.updateMarkerOrientation();
      this.renderer.resetCamera();
      this.renderWindow.render();
    },
    //wireframe representation
    setRepresentation(x) {
      return (this.representation = Number(x));
    },
    // cone surface resolution
    setResolution(y) {
      return (this.representation = Number(y));
    },
    //setup rotation
    increment() {
      this.orientation = (this.orientation % 360) + this.orientationIncrement;
    },
    toggleRotation() {
      if (this.isRotating) {
        this.stop();
        this.isRotating = false;
      } else {
        this.rotateObj();
        this.isRotating = true;
      }
    },
    rotateObj() {
      this.interval = setInterval(this.increment, this.intervalUpdateDelay);
    },
    //stop rotation
    stop() {
      clearInterval(this.interval);
    },
    //screenshot scene
    async screenShot() {
      const image = await this.renderWindow.captureImages()[0];
      const response = await axios.get(image, { responseType: "blob" });
      var fileURL = window.URL.createObjectURL(new Blob([response.data]));
      var fileLink = document.createElement("a");
      fileLink.href = fileURL;
      fileLink.setAttribute("download", "file.png");
      document.body.appendChild(fileLink);
      fileLink.click();
    },
    //test

    //axes orientation
    async updateOrienation() {
      this.axes = await vtkAxesActor.newInstance();
      this.orientationWidget = await vtkOrientationMarkerWidget.newInstance({
        actor: this.axes,
        interactor: this.renderWindow.getInteractor(),
      });
      this.orientationWidget.setEnabled(true);
      this.orientationWidget.setViewportCorner(
        vtkOrientationMarkerWidget.Corners.BOTTOM_RIGHT
      );
      this.orientationWidget.setViewportSize(0.16);
    },
    async updateGravitation() {
      const arrow = vtkArrowSource.newInstance();
      const arrowMapper = vtkMapper.newInstance();
      const arrowActor = vtkActor.newInstance({ scale: [1, 1, 1] });
      arrow.setDirection(0, -1, 0);
      //
      this.widget = await vtkOrientationMarkerWidget.newInstance();
      this.widget.setActor(arrowActor);
      this.widget.setInteractor(this.renderWindow.getInteractor());

      arrowMapper.setInputConnection(arrow.getOutputPort());
      arrowActor.setMapper(arrowMapper);

      this.widget.setEnabled(true);
      this.widget.setViewportCorner(
        vtkOrientationMarkerWidget.Corners.BOTTOM_LEFT
      );
      this.widget.setViewportSize(0.11);
    },
  },
  async mounted() {
    await this.$nextTick(); // wait for div with ref="vtkContainer" to exist

    // compute orientation increment based on RPM and visualisation update speed
    const rotationsPerMillisecond = Number(this.rpm.value) / (60 * 1000);
    this.orientationIncrement =
      360.0 * rotationsPerMillisecond * this.intervalUpdateDelay;
    //create screenRenderer
    const fullScreenRenderer = vtkFullScreenRenderWindow.newInstance({
      rootContainer: this.$refs.vtkContainer,
      // background: [0.1, 0.1, 0.1],
    });
    const renderer = fullScreenRenderer.getRenderer();
    const renderWindow = fullScreenRenderer.getRenderWindow();

    //create mappers and actors and sources
    const stlReader = vtkSTLReader.newInstance();
    const mapper = vtkMapper.newInstance({ scalarVisibility: false });

    const cone = vtkConeSource.newInstance({ radius: 20, height: 50 });
    const coneMapper = vtkMapper.newInstance();
    const coneActor = vtkActor.newInstance();

    const [xLength, yLength, zLength] = ["X", "Y", "Z"].map((xi) => {
      return 1000 * (this.world.end[xi] - this.world.origin[xi]) // m to mm
    });
    console.log(xLength, yLength, zLength);
    // FIXME: strictly speaking we also need to move the cube a bit (not all are centered)
    const cube = vtkCubeSource.newInstance({ xLength, yLength, zLength })
    const cubeMapper = vtkMapper.newInstance({ scalarVisibility: false });
    const cubeActor = vtkActor.newInstance();

    //map cube source into actor
    cubeMapper.setInputConnection(cube.getOutputPort());
    cubeActor.setMapper(cubeMapper);
    cubeActor.getProperty().setRepresentation(1);

    //map cone source into actor
    coneMapper.setInputConnection(cone.getOutputPort());
    coneActor.setMapper(coneMapper);
    cone.setResolution(50);
    //pass direction properties
    cone.setDirection(
      this.dir.dirX.value,
      this.dir.dirY.value,
      this.dir.dirZ.value,
    );
    console.log(this.pos);
    coneActor.setPosition(
      1000 * this.pos[0].posX.value,
      1000 * this.pos[0].posY.value,
      1000 * this.pos[0].posZ.value
    );

    //other nozzles
    for (let i = 1; i < this.pos.length; i++) {
      const nozzleActor = vtkActor.newInstance();
      const nozzleMapper = vtkMapper.newInstance();
      nozzleMapper.setInputConnection(cone.getOutputPort());
      nozzleActor.setMapper(nozzleMapper);
      nozzleActor.setPosition(
        1000 * this.pos[i].posX.value,
        1000 * this.pos[i].posY.value,
        1000 * this.pos[i].posZ.value
      );
      renderer.addActor(nozzleActor);
    }

    stlReader.setUrl(this.fileObj).then(() => {
      mapper.setInputConnection(stlReader.getOutputPort());
      this.actor.setMapper(mapper);
      
      this.actor.getProperty().setOpacity(0.1);
      renderer.addActor(this.actor);
      // this.axesOrienation();
      this.updateOrienation();
      this.updateGravitation();
      this.renderer= renderer;
      this.renderWindow = renderWindow;
    })
    //add actors to renderer
    renderer.addActor(cubeActor);
    renderer.addActor(coneActor);

    renderWindow.render();
    renderer.resetCamera();
  },
};
</script>

<style scoped>
h1 {
  color: white;
  position: absolute;
  font-size: 35px;
  margin-top: 720px;
  margin-left: 10px;
}
h1::before {
  position: absolute;
  color: white;
  content: "→";
  top: -15px;
  left: -0.2em;
}
.size-container {
  position: relative;
  height: 800px;
}
.controls {
  position: absolute;
  top: 12%;
  left: 94.5%;
}
.ml{
    margin: 0.4em 0.1em;
}
</style>