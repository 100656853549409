<template>
  <div style="margin-left: 0.75em">
    <button
      class="button is-primary"
      :disabled="isLoading"
      @click="toggleModal"
    >
      <Loader
        v-if="isLoading"
        :is-loading="isLoading"
      />
      Compare
    </button>
    <div
    class="modal modal-fix-position"
    :class="{'is-active': isActive}"
  >
    <div
      class="modal-background"
      @click="toggleModal"
    />
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">
          Generate Comparison
        </p>
        <button
          class="delete"
          aria-label="close"
          @click="toggleModal"
        />
      </header>
      <section class="modal-card-body" v-if="jobsOptions.length > 0">
        <Field
          id="setup-title"
          class="title"
          v-model="title"
          label="Title"
        />
        <TableCheckbox
          id="compare-select"
          :selectedJob="selectedJob"
          v-model="jobsSelection"
          v-if="jobsOptions.length > 0"
          :options="jobsOptions"
          class="table-checkbox"
        />
      </section>
      <footer class="modal-card-foot">
        <button
          class="button mt"
          :class="{
            'is-warning': !allowedToSubmit,
            'is-success': allowedToSubmit,
          }"
          @click="submitJob"
          :disabled="isSubmitting || !allowedToSubmit"
        >
          Run
        </button>
        <button
          class="button"
          @click="toggleModal"
        >
          Cancel
        </button>
      </footer>
    </div>
  </div>

  </div>
</template>

<script>
import gql from 'graphql-tag';
import Loader from 'ist-skeleton-vue/src/components/Loader.vue';
import Field from "ist-skeleton-vue/src/components/Simulation/Field.vue";
import TableCheckbox from "../components/TableCheckbox.vue";


export default {
  components: {
    Loader,
    TableCheckbox,
    Field,
  },
  props: {
    isLoading: Boolean,
    selectedJob: Object,
  },
  emits: ['generateJobs'],
  data() {
    return {
      isActive: false,
      // validating
      title: { value: "", ok: false },
      jobValidates: false,
      // selection
      jobsOptions: [],
      isJobsLoading: false,
      jobsAll: {},
      jobsSelection: { value: {}, },
      plotsLoaded: {},
      drumOptions: [
        {
          value: 0,
          url: `${process.env.BASE_URL}rotated_drum.stl`,
          label: "Rotated drum",
        },
        {
          value: 1,
          url: `${process.env.BASE_URL}pilot_scale.stl`,
          label: "Pilot scale",
        },
        {
          value: 2,
          url: `${process.env.BASE_URL}industrial_scale.stl`,
          label: "Industrial scale",
        },
      ],
      // tokens
      tokensAvailable: 0,
      tokenCounter: 1,
      // run
      isSubmitting: false,
    };
  },
  computed : {
    currentlySelectedJobs() {
      return Object.entries(this.jobsSelection.value).filter(([, selected]) => selected).map(([id, ]) => id);
    },
    allowedToSubmit() {
      return this.tokenCounter <= this.tokensAvailable && this.title.ok && this.currentlySelectedJobs.length > 1 && this.currentlySelectedJobs.length <= 5;
    },
  },
  methods: {
    toggleModal() {
      this.isActive = !this.isActive
    },
    async submitJob() {
      this.isSubmitting = true;
      try { 
        const inputData = { workflow: 'comparison' };
        inputData.title = this.title.value;
        inputData.jobIds = this.currentlySelectedJobs;
        await this.$apollo.mutate({
          mutation: gql`
          mutation addTabletCoaterComparisonJobAndTasks($product_name: ProductName!, $tasks: [TabletCoaterComparisonTaskIn]!, $job_name: String!) {
            job: addTabletCoaterComparisonJobAndTasks(product_name: $product_name, tasks: $tasks, job_name: $job_name) {
              _id
            }
          }`,
          variables: {
            product_name: this.$product.id,
            job_name: this.title.value,
            tasks: [inputData],
          },
        });
        this.$router.push('/home');
      }
      catch (error) {
        console.error(error);
      }
    },
    async generateJobs() {
      this.isJobsLoading = true;
      const response = await this.$apollo.query({
        query: gql`
          query getJobs($product_name: ProductName!) {
            jobs: getJobs(product_name: $product_name) {
              _id
              name
              status
              created_at
              tasks {
                _id
                status
                input
              }
            }
          }
        `,
        variables: {
          product_name: this.$product.id,
        },
      });
      const jobs = {};
      const options = [];
      const selected = {};
      response.data.jobs.filter((job) => job.status === 'completed' && job.tasks[0].status === 'success' && job.tasks[0].input.workflow === 'simulation').forEach((job) => {
        jobs[job._id] = job;
        options.push({
          value: job._id,
          label: job.name,
          created: this.$filters.formatDate(job.created_at),
          coater: this.drumOptions[0].label,
          rpm: job.tasks[0].input.drumRotationSpeed,
          load: job.tasks[0].input.mass,
        });
        selected[this.selectedJob._id] = true
        selected[job._id] = false;
      });
      this.jobsAll = jobs;
      this.jobsOptions = options;
      this.jobsSelection.value = selected;
      this.isJobsLoading = false;
    },
    async fetchTokens() {
      try {
        const response = await this.$apollo.query({
          query: gql`
          query getUserSubscription($product_name: ProductName!) {
            account: getUserSubscription(product_name: $product_name) {
              tokens_available
            }
          }`,
          variables: {
            product_name: this.$product.id,
          },
        });
        this.tokensAvailable = response.data.account.tokens_available;
      } catch (error) {
        console.error(error);
      }
    },
  },
  async mounted() {
    this.fetchTokens();
    this.generateJobs()
  },
};
</script>

<style scoped>
.icon-with-margin {
  margin-right: 0.5em;
}
.modal-fix-position{
  position: fixed;
  width: 100%;
}
.modal-card {
  width: 60%;
}
.title{
  width: 90%;
  margin: auto;
}
</style>
