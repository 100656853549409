import core from 'ist-skeleton-vue';
import apiConfig from './config/api-config';
import apolloConfig from './config/apollo-config';
import iamConfig from './config/iam-config';
import productConfig from './config/product-config';
import supportConfig from './config/support-config';
import Simulation from './views/Simulation.vue';
import Results from './views/Results.vue';
import Home from './views/Home.vue';
import Drum from './components/VtkScenes/Drum.vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCamera } from '@fortawesome/free-solid-svg-icons/faCamera';
import { faCube } from '@fortawesome/free-solid-svg-icons/faCube';
import { faBackwardStep } from '@fortawesome/free-solid-svg-icons/faBackwardStep';
import { faForwardStep } from '@fortawesome/free-solid-svg-icons/faForwardStep';
import { faPlay } from '@fortawesome/free-solid-svg-icons/faPlay';
import { faPause } from '@fortawesome/free-solid-svg-icons/faPause';
import { faRepeat } from '@fortawesome/free-solid-svg-icons/faRepeat';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import { faZ } from '@fortawesome/free-solid-svg-icons/faZ';
import { faY } from '@fortawesome/free-solid-svg-icons/faY';
import { faX } from '@fortawesome/free-solid-svg-icons/faX';

library.add(faCamera, faCube, faForwardStep, faBackwardStep, faInfoCircle, faZ, faX, faY, faPlay, faPause, faRepeat);

const globalConfig = {
  api: apiConfig,
  apollo: apolloConfig,
  iam: iamConfig,
  product: productConfig,
  router: [
    {
      path: '/jobs/new',
      name: 'simulation',
      component: Simulation,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/jobs/:id',
      name: 'job',
      component: Results,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/home',
      name: 'home',
      component: Home,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/drum',
      name: 'drum',
      component: Drum,
      meta: {
        requiresAuth: true,
      },
    },
  ],
  support: supportConfig,
};

const app = core.setup(globalConfig);
app.mount('#app');
