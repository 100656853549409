// const clientId = 'bfc0be7b-3021-4dac-900f-177d395a8827'; // b2c-msal2-ist-cardio-xps-vue
// authority: https://github.com/AzureAD/microsoft-authentication-library-for-dotnet/wiki/AAD-B2C-specifics
// https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/1795
// https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/1999
const b2cAuthority = 'istplatformb2c.b2clogin.com';
const publisherDomain = 'istplatformb2c.onmicrosoft.com';
const loginPolicy = 'B2C_1_signin_2';
const resetPasswordPolicy = 'B2C_1_resetpassword_2';

const config = {
  auth: {
    clientId: '531541e6-ed8b-4761-a364-ef35452f1ea4',
    authority: `https://${b2cAuthority}/${publisherDomain}/${loginPolicy}`,
    knownAuthorities: [b2cAuthority],
    redirectUri: window.location.origin,
    navigateToRequestUrl: false,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          // eslint-disable-next-line no-useless-return
          return;
        }
        // console.log(message); // uncomment for MSAL debugging output
      },
      piiLoggingEnabled: false,
    },
  },
  scopes: [
    'openid', // needed for login request
    `https://${publisherDomain}/api-msal2/user_impersonation`,
  ],
  resetPasswordFlow: `https://${b2cAuthority}/${publisherDomain}/${resetPasswordPolicy}`,
};

export default config;
