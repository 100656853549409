<template>
  <div class="section">
    <div class="container">
      <div class="tile is-ancestor">
        <div class="tile is-vertical">
          <div class="tile">
            <div class="tile is-4 is-vertical is-parent">
              <div class="tile is-child box">
                <NewSimulation />
              </div>
              <div class="tile is-child box">
                <Support />
              </div>
            </div>
            <div class="tile is-parent">
              <div class="tile is-child box">
                <LatestActivity />
              </div>
            </div>
          </div>
          <div class="tile">
            <div class="tile is-parent">
              <div class="tile is-child box">
                <HowToUse>
                  <p>
                    To create a new simulation, pick a coater, then specify the tablets and spray parameters.
                    There is a second mode of operation that allows you to compare different simulation runs.
                  </p>
                  <p style="margin-top: 0.75em;">
                    The simulation creation is divided into three steps.
                    First, assign a title to the simulation for bookkeeping purposes, then pick a coater and its desired rotation speed.
                    Second, fill in the tablet paramaters such as total mass and shape, and optionally particle interaction values.
                    Finally, describe the spray that is made of solvents and solids, and the nozzle setup.
                  </p>
                  <p style="margin-top: 0.75em;">
                    A fourth step summarizes your inputs and offers a preview of the turning coater and the nozzles inside it.
                  </p>
                  <p style="margin-top: 0.75em;">
                    Detailed information on how to use SimTabletCoater is provided in the user manual below.
                  </p>
                  <ul style="margin-top: 0.75em;">
                    <li>
                      <a
                        :href="`${publicPath}sim_tablet_coater.pdf`"
                        target="_blank"
                        rel="noopener noreferrer"
                      >User Manual
                      </a>
                    </li>
                  </ul>
                </HowToUse>
              </div>
            </div>
          </div>
          <div class="tile">
            <div class="tile is-parent">
              <div class="tile is-child box">
                <Documentation />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import HowToUse from 'ist-skeleton-vue/src/components/Home/HowToUse.vue';
import LatestActivity from 'ist-skeleton-vue/src/components/Home/LatestActivity.vue';
import NewSimulation from 'ist-skeleton-vue/src/components/Home/NewSimulation.vue';
import Support from 'ist-skeleton-vue/src/components/Home/Support.vue';
import Documentation from '../components/Documentation.vue';

export default {
  components: {
    HowToUse,
    LatestActivity,
    NewSimulation,
    Support,
    Documentation,
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
  computed: mapState({
    userName: (state) => {
      if (state.iam.user) {
        return state.iam.user.name;
      }
      return 'user';
    },
  }),
};
</script>
