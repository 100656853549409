<template>
  <div class="table-container">
    <table
      id="DirectionTable"
      class="table ist-table is-striped"
      style="margin-top: 1.5em"
    >
      <thead>
        <tr>
          <th>Title</th>
          <th>Created</th>
          <th>Coater</th>
          <th>RPM</th>
          <th>Load (kg)</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="opt in options" :key="opt.value">
          <td class="left">
            <div class="field">
              <input
                :id="`sim-${opt.value}`"
                type="checkbox"
                :name="opt.value"
                :checked=" selectedJob._id === opt.value ? true : modelValue.value[opt.value]"
                @change="onChange"
              >
              <router-link
                :to="`/jobs/${opt.value}`"
                class="is-primary"
              >
              <label
                :for="`sim-${opt.value}`"
                class="checkbox"
                style="margin-left: 0.75em;"
                v-html="opt.label"
              />
              </router-link>

            </div>
          </td>
          <td class="left">{{ opt.created }}</td>
          <td class="left">{{ opt.coater }}</td>
          <td>{{ opt.rpm }}</td>
          <td>{{ opt.load }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    id: String,
    help: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    selectedJob: {
      type: Object,
      default() {
        return {
          id: Number,
          status: String,
          Tasks: [],
        }
      },
    },
    modelValue: {
      type: Object,
      default() {
        return {
          one: true,
          two: false,
        };
      },
    },
    options: {
      type: Array,
      default() {
        return [
          { value: 'one', label: 'option one' },
          { value: 'two', label: 'option two' },
        ];
      },
    },
  },
  emits: ['update:modelValue'],
  methods: {
    onChange(event) {
      const newModel = this.modelValue;
      newModel.value[event.target.name] = event.target.checked;
      this.$emit('update:modelValue', newModel);
    },
  },
};
</script>

<style scoped>

</style>
