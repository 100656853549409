<template>
  <div class="documentation">
    <h1 class="title">
      Documentation
    </h1>
    <p>
      SimTabletCoater is based on the XPS software developed by RCPE, and is described in the publications below.
    </p>
    <div class="with-space">
      <h2 class="title is-4">
        Publications
      </h2>
      <ul>
        <li class="with-space">
          <span>
            Comparison of video analysis and simulations of a drum coating process
          </span>
          <br>P. Boehling, G. Toschko, R. Dreu, S. Just, P. Kleinebudde, A. Funke, H. Rehbaum, Eur. J. Pharm. Sci.
          <b>2017</b>
          <br>DOI: 10.1016/j.ejps.2017.03.031.
        </li>
        <li class="with-space">
          <span>
            Analysis of large-scale tablet coating : Modeling , simulation and experiments
          </span>
          <br>P. Boehling, G. Toschkoff, K. Knop, P. Kleinebudde, S. Just, A. Funke, H. Rehbaum, J.G. Khinast, Eur. J. Pharm. Sci.
          <b>2016</b>
          <br>DOI: 10.1016/j.ejps.2015.12.022
        </li>
        <li class="with-space">
          <span>
            Simulation of a tablet coating process at different scales using DEM
          </span>
          <br>P. Boehling, G. Toschkoff, S. Just, K. Knop, P. Kleinebudde, A. Funke, H. Rehbaum, P. Rajniak, J.G. Khinast, Eur. J. Pharm. Sci.
          <b>2016</b>
          <br>DOI: 10.1016/j.ejps.2016.08.018
        </li>
        <li class="with-space">
          <span>
            Validating a Numerical Simulation of the ConsiGma(R) Coater
          </span>
          <br>P. Boehling, D. Jacevic, F. Detobel, J. Holman, L. Wareham, M. Metzger, J.G. Khinas, AAPS PharmSciTech.
          <b>2021</b>
          <br>DOI: 10.1208/s12249-020-01841-7
        </li>
        <li class="with-space">
          <span>
            Industrial scale simulations of tablet coating using GPU based DEM: A validation study
          </span>
          <br>H. Kureck, N. Govender, E. Siegmann, P. Boehling, C. Radeke, J.G. Khinast,  Chem. Eng. Sci.
          <b>2019</b>
          <br>DOI: 10.1016/j.ces.2019.03.029
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped>
div.documentation {
  padding-right: 2em;
  line-height: 1.7;
}
</style>
